import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Button, Row, Col } from "reactstrap";

class UserProfile extends Component {

    render() {

        if (!this.props.state.session)
            return <>
                <Row>
                    <Col sm={12}>
                        <h1 className='centerText'>Uuups ... <span role="img" aria-label="thinking">🤔</span></h1>
                        <p className='centerText'>da is ja jemand nicht eingeloggt, oder?</p>
                        <p className='centerText'><Button color="link" onClick={(e) => this.props.history.push('/regulars/login')}>zum Login</Button></p>
                    </Col>
                </Row>
            </>;

        return <>
            <Row>
                <Col sm={12}>
                    <h1>Profil für {this.props.state.session.contact.email} <span role="img" aria-label="bunniez">👯</span></h1>
                    <ul>
                        <li>Name: {this.props.state.session.contact.given_name} {this.props.state.session.contact.family_name}</li>
                        <li>Mail: {this.props.state.session.contact.email}</li>
                        <li>
                            Meine Events:
                            <ul>
                                {this.props.state.session.event_registrations.map(r =>
                                    <li key={r}>{r}</li>
                                )}
                            </ul>
                        </li>
                    </ul>
                </Col>
            </Row>
        </>;
    }
}

export default withRouter(UserProfile);