import React from 'react';
import PropTypes from 'prop-types';
import { Col, Container, Row, Spinner } from 'reactstrap';

const Loading = props => {
  return (
    <div className="loading">
      <Container>
        <Row>
          <Col sm={12}>
            <Spinner
              color="light"
              type="grow"
            />
            <span>guess what, its loading...</span>
            <p>If this takes longer than 5 seconds, please <a href="mailto:it@hausgemacht.org?subject=%5BZK9%5D%20Fehler%20im%20Prozess&body=Browser%3A%0D%0A(mit%20welchem%20Browser%20hast%20du%20die%20Seite%20aufgerufen%3F%20z.B.%20Google%20Chrome%2C%20Safari)%0D%0A%0D%0AEndger%C3%A4t%20(Device)%3A%0D%0A(z.B.%20Desktop%20Windows%2010%20oder%20Smartphone%20iPhone)%0D%0A%0D%0ASchritt%20(Step)%3A%0D%0A(Welchen%20Schritt%20hast%20du%20versucht%20durchzuf%C3%BChren%3F%20z.B.%20Sprache%20ausw%C3%A4hlen%2C%20Frage%20beantworten)%0D%0A%0D%0A" target="_blank">contact us</a>.</p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

Loading.propTypes = {};

export default Loading;
