import React, { Component } from "react";
import { Row, Col, Button } from "reactstrap";
import App from "../App";

export class Register extends Component {

    state = {
        registrationCode: null,
        registrationObject: null,
        pwd: '',
        pwdCheck: '',
        consent: false,
        registration_response: null
    }

    submitPasswordHandler = async () => {

        let hashedPwd = await App.hashText(this.state.pwd);
        console.log("hashedPwd", hashedPwd);

        fetch(
          process.env.REACT_APP_API_BASE_URL +
            "/api/users/register?code=" +
            this.state.registrationCode +
            "&pwd=" +
            hashedPwd
        )
          .then((res) => res.json())
          .then((json) => this.setState({ registration_response: json }));
    }

    componentDidMount = () => {

        var params = new URLSearchParams(this.props.location.search);
        if (params.has('code')) {

            let code = params.get('code');
            let codeInfoPart = code.split('|')[0];
            let regCodeObject = JSON.parse(window.atob(codeInfoPart));

            this.setState({ registrationCode: code, registrationObject: regCodeObject });
            console.log("reg. object:", regCodeObject);
        }
    }

    render() {

        let passwordsSet = this.state.pwd && this.state.pwdCheck && this.state.pwd.length === this.state.pwdCheck.length;
        let passwordsMatch = passwordsSet && this.state.pwd === this.state.pwdCheck;

        if (!this.state.registrationObject)
            return (
                <Row>
                    <Col sm={12}>
                        <h1 className='centerText'>Uuups <span role="img" aria-label="thinking">🤔</span> ...</h1>
                        <p className='centerText'>Da fehlt aber was, oder? Diese Seite kann nur mit dem Parameter 'code' aufgerufen werden, wie du ihn mit der Mail bekommen hast ...</p>
                    </Col>
                </Row>
            );

        if (this.state.registration_response && !this.state.registration_response.success) {
            return (
                <Row>
                    <Col sm={12}>
                        <h1 className='centerText'>Sorry {this.state.registrationObject.contact_info.given_name}, ...
                            <br /><span role="img" aria-label="thinking">🤔</span></h1>
                        <p className='centerText'>Da hat jetzt aber etwas nicht so geklappt, wie wir uns das vorgestellt haben <span role="img" aria-label="pensive face">😔</span></p>
                        <p className='centerText' style={{ color: 'red'}}>{this.state.registration_response.message}</p>
                    </Col>
                </Row>
            );
        }

        if (this.state.registration_response && this.state.registration_response.success) {
            return (
                <Row>
                    <Col sm={12}>
                        <h1 className='centerText'>Yay! {this.state.registrationObject.contact_info.given_name}, ...
                            <br /><span role="img" aria-label="hug">🤗</span><span role="img" aria-label="hug">🤗</span><span role="img" aria-label="hug">🤗</span></h1>
                        <p>Es hat geklappt! Du bist ab sofort bei uns registriert! Melde dich mal an, um zu sehen, ob eh alles funktioniert!</p>
                        <p className='centerText'><Button variant="link">Home <span role="img" aria-label="home">🏠</span></Button></p>
                    </Col>
                </Row>
            );
        }

        return (
            <Row>
                <Col sm={12}>
                    <h1 className='centerText'>Hallo {this.state.registrationObject.contact_info.given_name}!<br /><span role="img" aria-label="hug">🤗</span><span role="img" aria-label="heart">❤</span></h1>
                    <p>Schön, dass du dich registrieren möchtest! Setze einfach das Passwort das du verwenden möchtest. Und keine Angst: Passwort, und alle deine persönlichen Daten (Mail, Name, etc.) werden gehasht bzw. verschlüsselt gespeichert. Bleibt alles unter uns, ok? <span role="img" aria-label="secret">🤐</span></p>
                    <p>Das sind die Daten, die wir über dich stark verschlüsselt speichern werden:</p>
                    <ul>
                        <li>Vorname: {this.state.registrationObject.contact_info.given_name}</li>
                        <li>Nachname: {this.state.registrationObject.contact_info.family_name}</li>
                        <li>e-Mail: {this.state.registrationObject.contact_info.email}</li>
                    </ul>
                    <p className='centerText'><input type="password" value={this.state.pwd} onChange={e => this.setState({ pwd: e.target.value })} placeholder="passwort" /></p>
                    <p className='centerText'><input type="password" value={this.state.pwdCheck} onChange={e => this.setState({ pwdCheck: e.target.value })} placeholder="passwort" /></p>
                    <p className='centerText' style={{ color: 'red' }}>{passwordsSet && !passwordsMatch && <span>Sag, hast du dich vertippt?</span>}</p>
                    <p><input type="checkbox" checked={this.state.consent} onChange={(e) => this.setState({ consent: e.target.checked })} /> Ja, ich bin einverstanden, dass diese Daten über mich gespeichert werden.</p>
                    <p className='centerText' style={{ color: 'red' }}>{passwordsMatch && !this.state.consent && <span> Du musst uns aber schon erlauben, die Daten über dich zu speichern, sonst wird das gar nichts!</span>}</p>
                    <p className='centerText'><Button disabled={!passwordsSet || !passwordsMatch || !this.state.consent} onClick={this.submitPasswordHandler}>Registrieren!</Button></p>
                </Col>
            </Row>
        );
    }
}