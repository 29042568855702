import React from 'react';
import PropTypes from 'prop-types';
import PageHead from '../../PageHead/PageHead';

const QIntro = (props) => {
  return (
    <>
      {props.lang === "de" ? (
        <PageHead
          title={"FREUND*INNEN DER KÖRPERFREIHEIT, ES IST WIEDER SOWEIT! 🫶🌈"}
        >
          <p>
            Danke für dein Interesse an ZusammenKommen 12. Auch dieses Mal
            selektieren wir online die Gäste vor – das ist wegen des Andrangs
            wichtig, um für alle die bestmögliche Zeit zu garantieren. Diesmal
            steht, anlässlich des Pride Months, alles unter besagtem Motto. Das
            bedeutet natürlich auch, dass wir euch am liebsten in allen Farben
            des Regenbogens antanzen sehen möchten. Und klarerweise haben wir
            uns diesmal auch wieder einige Specials für euch einfallen lassen.
            Einige von euch werden zum Beispiel die Möglichkeit haben, schon bei
            Sonnenuntergang in den Abend zu tanzen, viele von euch können
            diesmal am Mainfloor unter einer Decke stecken und unsere Rainbow
            Couples werden Bündnisse aller Art während des Bondage Workshops
            schließen dürfen.
          </p>

          <p>
            Wie immer möchten wir sehen, wie sehr ihr euch mit uns und für das
            Event wichtigen Themen auseinandergesetzt habt. Die Fragen werden
            pro User*in randomisiert zugeteilt.
          </p>

          <p>
            Es gibt keine vordefinierte Mindestpunktzahl die erreicht werden
            muss. Wie viele Punkte für eine Zusage ausreichen, bestimmt die
            durchschnittliche maximale Punktezahl der Bewerbenden. Dadurch
            entsteht automatisch ein gemeinsamer Mindeststandard den die
            Community erstellt.
          </p>

          <p>
            Die Durchführung dauert im Schnitt 30-45 Minuten und kann nicht
            zwischengespeichert werden. Nehmt euch also in einem ruhigen Moment
            viel Zeit zum Ausfüllen.
            <br />
            Wir kürzen den Begriff Awareness mit AWA ab, also wundert euch
            nicht, wenn ihr das lest.
          </p>

          <p>
            Der Bewerbungsprozess setzt sich, wie letztes Mal, aus zwei Teilen
            zusammen:
          </p>
          <ol>
            <li>Motivationsschreiben</li>
            <li>
              Multiple Choice Test zu den 4 Kategorien: Allgemeinwissen /
              Kollektiv / Wissenswertes / Verhalten
            </li>
          </ol>

          <p>
            - Alle Antworten können richtig sein, eine Antwort ist immer
            mindestens richtig. Es ist möglich, Minuspunkte zu bekommen, wenn
            man falsch liegt.
          </p>

          <p>
            Die erste offene Frage (Selbstbeschreibung) ist besonders
            ausschlaggebend, hier erfahren wir was euch motiviert dabei zu sein
            und womit ihr zu etwas mehr Sicherheit auf dem Event beitragt. Und
            kein Stress, es gibt hier kein Zeitlimit! Falls nicht sofort klar
            ist, worum es geht oder zwischen Antwortmöglichkeiten geschwankt
            wird, informiert euch online oder fragt dort nach, wo das Wissen
            schon existiert. Wir sind immer dafür, dass Leute voneinander lernen
            ❤️
          </p>

          <p>
            Was den Namen angeht, den ihr bei der Bewerbung angebt, haben wir
            ein paar technische Fortschritte! Unser neues Vereinsmitglied der
            Ausweisscanner macht es möglich, dass auch ein Teil der
            Ausweisnummer von einem biometrischen Ausweis stattdessen verwendet
            werden kann. Wenn ihr also den Namen in euren Dokumenten, aus
            welchem Grund auch immer, nicht angeben wollt, dann kann auch die
            Nummer verwendet werden, um vor Ort zu kontrollieren, ob das Ticket
            und Menschi übereinstimmen. Also entweder Name oder Nummer von dem
            Ausweis, mit dem ihr bei der Party auftauchen werdet.
            Selbstverständlich muss somit entweder der Name oder die Nummer mit
            euren Dokumenten übereinstimmen.
          </p>

          <p>
            Du möchtest dich in einer Gruppe anmelden? Eine Gruppe darf bis zu 4
            Personen beinhalten wobei jede*r einen eigenen Test & Text abgeben
            muss. Am Ende werdet ihr gebeten, einen deckungsgleichen
            Gruppennamen einzutragen und wir berechnen den Punktedurchschnitt
            der Gruppe.
          </p>

          <p>
            An alle, die ab diesem Event in den Pool unserer Besties aufgenommen
            werden wollen: Ihr bekommt die genauen Infos dazu in eurer
            Bestätigungsmail nach der Bewerbung.
          </p>

          <p>
            PS: Solltest du trotz aller Mühe eine Absage bekommen - es liegt
            nicht an dir, es liegt an uns. Wir überlegen uns vorher einen
            Durchmischung der Gäste und gewichten danach. Wir sehen uns dann
            eben beim nächsten Mal! ❤️
          </p>

          <p>Wenn alles soweit klar ist, dann lass uns stolz vorangehen!</p>
        </PageHead>
      ) : (
        <PageHead title={"FRIENDS OF BODY FREEDOM, IT'S TIME AGAIN! 🫶🌈"}>
          <p>
            Thank you for your interest in ZusammenKommen 12. Once again, we are
            pre-selecting guests online - this is important because of the
            crowds, to guarantee the best possible time for everyone. This time,
            on the occasion of pride month, everything is set under this motto.
            Of course, this also means that we would like to see you dance
            dressed in all the colors of the rainbow. And certainly, we have
            also come up with some specials for you this time. For istance, some
            of you will have the possibility to dance into the evening with the
            sunset, a few will be able to be in cahoots with each other on the
            main floor and more or less binding experiences of all kinds will be
            able to be made for our rainbow couples in the bondage workshop.
          </p>
          <p>
            As always, we want to see how much you've engaged with us & topics
            related to the event. The questions will be randomly assigned for
            every user.
          </p>
          <p>
            There is no predefined minimum number of points that must be
            achieved. How many points are sufficient for acceptance is
            determined by the average maximum score of the applicants. This
            automatically creates a common minimum standard that the community
            establishes.
          </p>
          <p>
            The process takes an average of 30-45 minutes and cannot be saved.
            So do it in a quiet moment when you have plenty of time.
            <br />
            We abbreviate the term awareness as AWA, so don't be surprised if
            you read that.
          </p>
          <p>
            The application process is composed of two parts, just like last
            time:
          </p>
          <ol>
            <li>text of motivation</li>
            <li>
              multiple choice test on the 4 categories: general knowledge /
              collective / important topics / behavior
            </li>
          </ol>
          <p>
            - all answers can be correct, one answer is always at least correct.
            It is possible to get minus points if you are wrong.
          </p>
          <p>
            The first open question (self-description) is especially crucial,
            here we will find out what motivates you to be there and what you
            contribute to the event. And no stress, there is no time limit! If
            it's not immediately clear what it's about, or you're wavering
            between answer choices, check online or ask where the knowledge
            already exists. We are always in favor of people learning from each
            other ❤️
          </p>
          <p>
            Concerning the name you must enter on your application - we have
            made technical progress! Our newest member, the ID scanner, makes it
            possible to also enter part of the ID number from the biometric ID
            you will use to come to the event instead of a name. So if for
            whatever reason, you don't want to put the name in your documents on
            the application, the number can also be used to check on the spot if
            the ticket and the guest match. Obviously, either the name or the
            number must be the same as in your documents.
          </p>
          <p>
            Want to sign up for a group? A group may contain up to 4 people
            where each person must submit their own test & text. At the end, you
            will be asked to enter a congruent group name and we will calculate
            the score average of the group.
          </p>
          <p>
            To all those who want to join the pool of our besties from this
            event onwards: You will get the precise info in your confirmation
            email after the application.
          </p>
          <p>
            PS: If you get a rejection despite all your efforts - it's not you,
            it's us. We consider a mixture of guests beforehand and weight them
            accordingly. See you next time! 🫶
          </p>
          <p>All good? Then let’s proudly move along!</p>
        </PageHead>
      )}
    </>
  );
};

QIntro.propTypes = {
  confirmReadIntro: PropTypes.func
};

export default QIntro;
