import React from "react";
import { Col, Row } from "reactstrap";

export const BiometricIdInfo = ({ lang }) => {
  return (
    <Row>
      <Col md={4}>
        <a href="/biometric_id_symbol.png" target="_blank">
          <figure>
            <img
              src="/biometric-id-symbol.png"
              alt=""
              width={1200}
              height={738}
              style={{
                maxWidth: "100px",
                height: "auto",
                backgroundColor: "white",
              }}
            />
            <figcaption
              style={{
                color: "#fff",
              }}
            >
              {lang === "de" ? "Biometrisches Symbol" : "Biometric Symbol"}
            </figcaption>
          </figure>
        </a>
      </Col>
      <Col md={4}>
        <a href="/biometrischer-personalausweis-at.jpg" target="_blank">
          <figure>
            <img
              src="/biometrischer-personalausweis-at.jpg"
              alt=""
              width={600}
              height={371}
              style={{
                maxWidth: "150px",
                height: "auto",
              }}
            />
            <figcaption
              style={{
                color: "#fff",
              }}
            >
              {lang === "de"
                ? "Beispiel biometrischer Personalausweis"
                : "Example of an biometric ID Card"}
            </figcaption>
          </figure>
        </a>
      </Col>
      <Col md={4}>
        <a href="/biometrischer-reisepass-at.jpeg" target="_blank">
          <figure>
            <img
              src="/biometrischer-reisepass-at.jpeg"
              alt=""
              width={541}
              height={721}
              style={{
                maxWidth: "100px",
                height: "auto",
              }}
            />
            <figcaption
              style={{
                color: "#fff",
              }}
            >
              {lang === "de"
                ? "Beispiel biometrischer Reisepass"
                : "Example of an biometric Passport"}
            </figcaption>
          </figure>
        </a>
      </Col>
    </Row>
  );
};
