import React, { Component } from "react";
import {
    Row,
    Col,
    Button,
    Card,
    CardText,
    CardSubtitle,
    CardBody,
    CardTitle,
    CardGroup, Container, CardImg, CardFooter
} from "reactstrap";
import EventCard from './EventCard/EventCard';
import { withTranslation, useTranslation } from 'react-i18next';
import PageHead from './PageHead/PageHead';

class HomeComponent extends Component {

    state = {
        events: [],
        archive: []
    }

    componentDidMount() {

        fetch(process.env.REACT_APP_API_BASE_URL + "/api/events")
          .then((res) => res.json())
          .then((json) => this.setState({ events: json }));

        fetch(process.env.REACT_APP_API_BASE_URL + "/api/version")
          .then((res) => res.text())
          .then((text) => console.log("version", text));
    }

    formatDate(date) {
        const localDate = new Date(date);
        const options = {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            timeZone: 'CET'
        };
        return localDate.toLocaleDateString('de-DE', options)
    }

    render() {

        return (
            <>
                <PageHead title={"Veranstaltungen"}
                    introText={"Das sind unsere Veranstaltungen, auf die du dich aktuell bewerben kannst, um ein Ticket zu erhalten."} />
                <Container>
                    <Row>
                        <Col>
                            <Row>
                                {this.state.events.map(event => {
                                    let registrationOpen = new Date(event.registration.close) > new Date();

                                    return (
                                        <Col key={'event_' + event.event_id}>

                                            {/*<EventCard {...event} />*/}
                                            <Card key={'event_' + event.event_id}
                                                onClick={registrationOpen ? (e) => this.props.history.push('/events/' + event.event_id + '/questionnaire') : ''}
                                                className="event-card"
                                            >
                                                <CardBody>

                                                    <CardTitle tag="h3">
                                                        {event.event_title}
                                                    </CardTitle>
                                                    <CardSubtitle
                                                        className="mb-2"
                                                        tag="h6"
                                                    >
                                                        {this.formatDate(event.event_date)}
                                                    </CardSubtitle>
                                                    <CardText>
                                                        {event.event_description}
                                                    </CardText>
                                                </CardBody>
                                                {event.registration.open || event.guestlist_capacity ?
                                                    <CardFooter>
                                                        <ul className="datalist">
                                                            {registrationOpen ?
                                                                <li>Anmeldung offen bis: {event.registration.close}</li> :
                                                                <li style={{ color: '#edc3ca' }}><strong>Bewerbungen geschlossen</strong></li>}

                                                            {event.guestlist_capacity ?
                                                                <li>Verfügbare
                                                                    Gästelistenplätze: {event.guestlist_capacity}</li> : ''}

                                                        </ul>
                                                    </CardFooter>
                                                    : ''}

                                            </Card>

                                        </Col>
                                    )
                                }
                                )}
                            </Row>
                            {this.state.archive.length > 0 ?
                                <Row>
                                    <Col>
                                        <h2>Vergangene:</h2>
                                        <Row className="row-cols-1 row-cols-sm-2 row-cols-md-3 g-3">

                                            {this.state.archive.map(event =>
                                                <Col>
                                                    <Card key={'event_' + event.event_id}
                                                    >
                                                        <CardBody>
                                                            <CardTitle tag="h5">
                                                                {event.event_title}
                                                            </CardTitle>
                                                            <CardSubtitle
                                                                className="mb-2 text-muted"
                                                                tag="h6"
                                                            >
                                                                {event.event_date}
                                                            </CardSubtitle>
                                                            <CardText>
                                                                {event.event_description}<br />

                                                                <ul>
                                                                    <li>Anmeldung offen bis: {event.registration.close}</li>
                                                                    <li>Verfügbare Gästelistenplätze: {event.guestlist_capacity}</li>
                                                                </ul>
                                                            </CardText>
                                                            <Button
                                                                color="primary"
                                                                onClick={e => this.props.history.push('/events/' + event.event_id + '/questionnaire')}>{event.event_title}</Button>
                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                            )}
                                        </Row>
                                    </Col>
                                </Row>
                                : ''}

                        </Col>
                    </Row>
                </Container>
            </>

        )
            ;
    }
}

const Home = withTranslation()(HomeComponent);
export { Home };
