import React, { Component } from 'react';
import {
    Row,
    Col,
    Badge,
    Table,
    Container,
    Input,
    FormGroup,
    Label,
    FormText,
    Button
} from 'reactstrap';
import PageHead from './PageHead/PageHead';

export class ApplicationInfo extends Component {

    state = {
        application: undefined,
        eventId: undefined,
        applicationId: undefined,
        newMember: undefined,
        isLoading: true
    }

    constructor(props) {
        super(props);
        console.log("constructed ApplicationInfo (props)", props);

        this.props = props;

        let eventId = props.match.params.eventId;
        let applicationId = props.match.params.applicationId;

        if (!eventId || !applicationId)
            throw Error("ApplicationInfo.js: eventItem ID or application ID not defined!");

        this.state = {
            eventId,
            applicationId
        }
    }

    componentDidMount = () => {

        this.loadStatus();

    }

    getStatusColor = (status) => {
        switch (status) {
            case 'pending':
                return 'secondary'
            case 'accepted':
                return 'green'
            case 'evaluated':
                return 'orange'
            case 'rejected':
                return 'red'
            default:
                return 'secondary'
        }
    }

    getStatusTitle = (status) => {
        switch (status) {
            case 'pending':
                return 'Bewerbung wurde noch nicht angesehen'
            case 'accepted':
                return 'Bewerbung wurde angenommen, Mail mit Kauflink versendet'
            case 'evaluated':
                return 'Bewerbung bereits bewertet'
            case 'rejected':
                return 'Der Club-Einlass wurde verwehrt, trotz validem Ticket'
            case 'expelled':
                return 'Rauswurf aus dem Club'
            case 'admitted':
                return 'Ist im Club'
            default:
                return;
        }
    }

    loadStatus = () => {
      fetch(
        process.env.REACT_APP_API_BASE_URL +
          "/api/events/" +
          this.state.eventId +
          "/applications/" +
          this.state.applicationId
      )
        .then((res) => res.json())
        .then((json) => {
          console.log("fetched application info", json);

          this.props.setLanguage(json.lang);

          this.setState({
            application: json,
            newMember: undefined,
            isLoading: false,
          });
        });
    };

    addMemberToGroup = (e) => {
      fetch(
        process.env.REACT_APP_API_BASE_URL +
          "/api/events/" +
          this.state.eventId +
          "/applications/" +
          this.state.newMember +
          "/setgroup",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            group_name: this.state.application.group_name,
          }),
        }
      )
        .then((res) => res.json())
        .then((res) => {
          if (!res) alert("didn't function, sorry ...");
          else this.loadStatus();
        });
    };

    render() {

        let appl = this.state.application;
        if (!appl)
            return <div>loading ...</div>;

        let lang = appl && appl.lang;

        let addFriendDe = "Freund:in zu '" + appl.group_name + "' hinzufügen:";
        let addFriendEn = "Add friend to '" + appl.group_name + "':";

        return <>
            {appl && <PageHead lang={this.props.state.language} title={'Status'} titleEn={"Status"}
                introText={'Hier siehst du den aktuellen Status deiner Bewerbung' + (appl.group_members ? (" und denen deiner Gruppe:") : ":")}
                introTextEn={"Here is the state of your application" + (appl.group_members ? (" and that of your fellows:") : ":")} />}
            <Container className="guestlist">
                <Row>
                    <Col sm="12" md={{ offset: 2, size: 8 }}>
                        {appl && <>
                            <Table className="guestlist__table" dark style={{ backgroundColor: '100%' }}>
                                <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className="is-selected">
                                        <td className="guestlist__item--id">{appl.response_id}</td>
                                        <td className="guestlist__item--status">
                                            <Badge color={this.getStatusColor()} pill title={this.getStatusTitle(appl.status)}>
                                                {appl.status}
                                            </Badge>
                                        </td>
                                    </tr>
                                    {appl.group_members && appl.group_members.filter(m => m.response_id != appl.response_id).map(e =>
                                        <tr key={e.response_id} id={e.response_id}>
                                            <td className="guestlist__item--id">{e.response_id}</td>
                                            <td className="guestlist__item--status">

                                                <Badge color={this.getStatusColor(e.status)} pill title={this.getStatusTitle(e.status)}>
                                                    {e.status}
                                                </Badge>

                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </Table>
                        </>}
                    </Col>
                </Row>
                {/*<Row>
                    <Col sm="12" md={{ offset: 2, size: 7 }}>
                        {appl && appl.group_members && <>
                            <FormGroup>
                                <Label for="iptMember">{lang == "de" ? addFriendDe : addFriendEn}</Label>
                                <Input bsSize="lg" required type="text" id="iptMember"
                                    value={this.state.newMember}
                                    onChange={(e) => {

                                        if (!e.target.value)
                                            return;

                                        let re = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
                                        let value = e.target.value.trim();
                                        let isValidValue = re.test(value);

                                        if (appl.group_members.some(m => m.response_id == value)) {
                                            alert("member already in group!");
                                            return;
                                        }

                                        this.setState({ newMember: isValidValue ? value : undefined });

                                    }}></Input>
                                <FormText color="white">
                                    {lang === "de"
                                        ? <>Gib die ID ein, um jemanden zu deiner Gruppe hinzuzufügen.</>
                                        : <>Enter ID to add your friend to your group!</>
                                    }
                                </FormText>
                            </FormGroup>
                        </>
                        }
                    </Col>
                    <Col sm="4" md={{ offset: 2, size: 3 }}>
                        <Button color="primary" size="lg"
                            disabled={!this.state.newMember || this.state.isLoading}
                            onClick={(e) => this.addMemberToGroup(e)}>+</Button>
                    </Col>
                </Row>*/}
            </Container>
        </>;
    }
}