import React, { Component } from 'react'
import { withRouter } from "react-router-dom"
import QrReader from 'react-qr-reader'
import {
    Button,
    Container,
    Row,
    Col,
    ButtonDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem, Input, Label, FormGroup, Form
} from 'reactstrap'
import App from '../App'

class Gatekeeper extends Component {
  state = {
    password: null,
    isValidPassword: null,

    eventId: "zk10",
    admissions: null,
  };

  initState = () =>
    this.setState({
      isProcessingScan: false, // true wenn Daten zu dem Scan geladen werden
      errorApplicantNotFound: null, // true wenn es keine Anmeldung zu dem Ticket gibt
      errorApplicantNotValid: null, // true wenn Bewerbung ungültig / nicht vorhanden
      errorApplicantAlreadyAdmitted: null, // true wenn Bewerber bereits drinnen sein soll
      errorGeneral: null, // true wenn System-Fehler aufgetreten ist

      qrContent: null, // inhalt des gescannten Barcodes
      pretixSecret: null, // Pretix-Code auf dem Ticket
      applicantOffline: null, // Offline-Datensatz des Tickets
      applicantOnline: null, // Online-Datensatz des Tickets

      isNameAsStated: null, // true: Name ist wie bei Bewerbung angegeben
      observedGender: null, // male / female / non-binary - wird nach Scan angegeben

      showMakeChoice: false, // Formular anzeigen um Name zu bestätigen und beobachteten Gender einzugeben
      showChoicesForRejection: false, // Formular anzeigen um Bewerber abzulehnen

      isGuestWithApplication: false,
      isGuestWithValidPretixTicket: false,
      entranceFee: null,
      previouslyNotAccepted: null,
    });

  componentDidMount = () => {
    this.initState();

    fetch(
      process.env.REACT_APP_API_BASE_URL +
        "/api/events/" +
        this.state.eventId +
        "/admissions"
    )
      .then((res) => res.json())
      .then((json) => this.setState({ admissions: json }));
  };

  obtainApplicantInfo = (applicationId) => {
    return fetch(
      process.env.REACT_APP_API_BASE_URL +
        "/api/events/" +
        this.state.eventId +
        "/applications/" +
        applicationId
    )
      .then((res) => res.json())
      .then((applicationInfo) => {
        console.log("info on server", applicationInfo);
        this.setState({ applicantOnline: applicationInfo });
      });
  };

  handleScan = (scanned) => {
    if (scanned && !this.state.isProcessingScan) {
      this.setState({ isProcessingScan: true, qrContent: scanned });
      console.log("scanned barcode:", scanned);

      let applicantID = null;
      let applicantAsOrdinaryGuest = null;
      let applicantAsAcceptedCandidate = null;
      let applicantAsNotAcceptedCandidate = null;
      let asPretixOnlyGuest = null;

      if (scanned.startsWith("ZK:{")) {
        let zkBarcodeJson = JSON.parse(scanned.substring(3));
        applicantID = zkBarcodeJson.g;

        applicantAsOrdinaryGuest = this.state.admissions.ordinary_guests.find(
          (a) => a.application_id == applicantID
        );
        applicantAsAcceptedCandidate = this.state.admissions.accepted.find(
          (a) => a.application_id == applicantID
        );
        applicantAsNotAcceptedCandidate =
          this.state.admissions.not_accepted.find(
            (a) => a.application_id == applicantID
          );
      } else {
        applicantAsOrdinaryGuest = this.state.admissions.ordinary_guests.find(
          (a) => a.pretix_secret == scanned
        );
        asPretixOnlyGuest = this.state.admissions.pretix_only.find(
          (a) => a.pretix_secret == scanned
        );

        if (applicantAsOrdinaryGuest)
          applicantID = applicantAsOrdinaryGuest.application_id;
      }

      let applicant = [
        applicantAsOrdinaryGuest,
        applicantAsAcceptedCandidate,
        applicantAsNotAcceptedCandidate,
      ].find((a) => a != null);

      console.log("applicantAsOrdinaryGuest: ", applicantAsOrdinaryGuest);
      console.log(
        "applicantAsAcceptedCandidate: ",
        applicantAsAcceptedCandidate
      );
      console.log(
        "applicantAsNotAcceptedCandidate: ",
        applicantAsNotAcceptedCandidate
      );
      console.log("asPretixOnlyGuest: ", asPretixOnlyGuest);

      if (asPretixOnlyGuest) {
        this.setState({
          applicantOffline: asPretixOnlyGuest,
          errorApplicantNotFound: false,
          isProcessingScan: false,
          showMakeChoice: true,
          isGuestWithApplication: false,
          isGuestWithValidPretixTicket: true,
          entranceFee: asPretixOnlyGuest.from_friendslist ? 25 : 0,
        });
      } else if (applicant) {
        this.obtainApplicantInfo(applicantID).then(() => {
          this.setState({
            applicantOffline: applicant,
            errorApplicantNotFound: false,
            showMakeChoice: true,
            isProcessingScan: false,
            isGuestWithApplication: true,
            isGuestWithValidPretixTicket: applicantAsOrdinaryGuest != null,
            entranceFee:
              applicantAsAcceptedCandidate || applicantAsNotAcceptedCandidate
                ? 40
                : 0,
          });
        });
      } else {
        this.setState({
          errorApplicantNotFound: true,
          applicantOffline: false,
          isProcessingScan: false,
          isGuestWithApplication: false,
          isGuestWithValidPretixTicket: false,
        });
      }
    }
  };

  handleError = (err) => {
    console.error(err);
    this.setState({ isProcessingScan: false, errorGeneral: true });
  };

  dismissApplicantNotFoundInfo = () => this.initState();
  dismissApplicantNotValidInfo = () => this.initState();

  getApplicantUri = () =>
    process.env.REACT_APP_API_BASE_URL +
    `/api/events/${this.state.eventId}/applications/${this.state.applicantOffline.application_id}`;

  getChangeStatusUri = (status) =>
    `${this.getApplicantUri()}/setstatus?status=${status}&code=${
      this.state.password
    }`;

  validatePassword = () => {
    if (!this.state.password) return false;

    fetch(
      process.env.REACT_APP_API_BASE_URL +
        `/api/events/${this.state.eventId}/checkpassword?pwd=${this.state.password}`
    )
      .then((res) => res.json())
      .then((json) => {
        if (json) this.setState({ isValidPassword: true });
        else alert("password invalid!");
      });
  };

  changeApplicantStatus = (fullUri) => {
    if (!this.state.isGuestWithApplication)
      alert("not application - not applicable!");

    try {
      fetch(fullUri)
        .then((res) => {
          if (!res.ok) {
            this.setState({ errorGeneral: "error during change status" });
            throw new Error(`${res.status} ${res.text()}`);
          }

          this.obtainApplicantInfo(this.state.applicantOffline.application_id);
        })
        .catch((error) => {
          console.log("error .... ", error);
          alert(error.message);
        });
    } catch (error) {
      alert(":-(" + error);
    }
  };

  admitApplicant = () => {
    if (!this.state.isGuestWithApplication)
      alert("not application - not applicable!");

    this.changeApplicantStatus(this.getChangeStatusUri("admitted"));
  };

  admitPretixOnly = () => {
    try {
      fetch(
        process.env.REACT_APP_API_BASE_URL +
          `/api/events/${this.state.eventId}/registerboxofficeentry?gender=${this.state.observedGender}`
      )
        .then((res) => {
          if (!res.ok) {
            this.setState({
              errorGeneral: "error during admission of Pretix-only",
            });
            throw new Error(`${res.status} ${res.text()}`);
          }
        })
        .catch((error) => {
          console.log("error .... ", error);
          alert(error.message);
        });
    } catch (error) {
      alert(":-( " + error);
    }

    alert("ok");
    this.initState();
  };

  rejectApplicant = (reason) => {
    if (!this.state.isGuestWithApplication)
      alert("not application - not applicable!");

    if (!reason) reason = window.prompt("please state reason for rejection:");

    this.changeApplicantStatus(
      `${this.getChangeStatusUri("rejected")}&note=${reason}`
    );
  };

  render() {
    console.log("state: ", this.state);

    if (!this.state.isValidPassword) return this.renderEnterPassword();

    if (this.state.errorApplicantNotFound)
      return this.renderBarcodeDoesNotCorrelateToApplication();

    if (this.state.entranceFee) return this.renderPayFeeInfo();

    if (this.state.showMakeChoice && this.state.isGuestWithApplication)
      return this.renderAdmissionChoice();

    if (this.state.showMakeChoice && !this.state.isGuestWithApplication)
      return this.renderGuestInfo();

    return this.renderQrReader();
  }

  renderEnterPassword = () => (
    <div>
      <Container>
        <Row>
          <Col
            sm="12"
            md={{
              offset: 2,
              size: 8,
            }}
          >
            <Form onSubmit={(e) => this.validatePassword()}>
              <FormGroup className="mb-2 me-sm-2 mb-sm-0 mt-4">
                <Label className="me-sm-2" for="gatekeepterPassword">
                  Password
                </Label>
                <Input
                  value={this.state.password || undefined}
                  onChange={(e) => this.setState({ password: e.target.value })}
                  id="gatekeepterPassword"
                  name="password"
                  placeholder="enter gatekeeper password"
                  type="text"
                />
              </FormGroup>
              <Button onClick={(e) => this.validatePassword()}>Enter</Button>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );

  renderBarcodeDoesNotCorrelateToApplication = () => (
    <div>
      <h1>Barcode does not correlate to any application!</h1>
      <p>{this.state.qrContent}</p>
      <button onClick={(e) => this.initState()}>OK</button>
    </div>
  );

  renderServerError = () => (
    <div>
      <h1>Server error! :-(</h1>
      <p>{this.state.applicantOffline.application_id}</p>
      <button onClick={(e) => this.initState()}>OK</button>
    </div>
  );

  getStatusIcon = (status) => {
    switch (status) {
      case "pending":
        return (
          <>
            <svg width="1em" height="1em" viewBox="0 0 16 16">
              <path
                fill="#c5b400"
                d="M9.05.435c-.58-.58-1.52-.58-2.1 0L.436 6.95c-.58.58-.58 1.519 0 2.098l6.516 6.516c.58.58 1.519.58 2.098 0l6.516-6.516c.58-.58.58-1.519 0-2.098L9.05.435zM8 4c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995A.905.905 0 0 1 8 4zm.002 6a1 1 0 1 1 0 2a1 1 0 0 1 0-2z"
              ></path>
            </svg>
            <span>{status}</span>
          </>
        );
      case "accepted":
        return (
          <>
            <svg width="1em" height="1em" viewBox="0 0 16 16">
              <path
                fill="#a9e35e"
                d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417L5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"
              ></path>
            </svg>
            <span>{status}</span>
          </>
        );
      case "evaluated":
        return (
          <>
            <svg width="1em" height="1em" viewBox="0 0 16 16">
              <path
                fill="#c5b400"
                d="M9.05.435c-.58-.58-1.52-.58-2.1 0L.436 6.95c-.58.58-.58 1.519 0 2.098l6.516 6.516c.58.58 1.519.58 2.098 0l6.516-6.516c.58-.58.58-1.519 0-2.098L9.05.435zM8 4c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995A.905.905 0 0 1 8 4zm.002 6a1 1 0 1 1 0 2a1 1 0 0 1 0-2z"
              ></path>
            </svg>
            <span>{status}</span>
          </>
        );
      case "rejected":
        return (
          <>
            <svg width="1em" height="1em" viewBox="0 0 16 16">
              <path
                fill="#a42a2a"
                d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293L5.354 4.646z"
              ></path>
            </svg>
            <span>{status}</span>
          </>
        );
      default:
        return (
          <>
            <svg width="1em" height="1em" viewBox="0 0 16 16">
              <path
                fill="#c5b400"
                d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.496 6.033h.825c.138 0 .248-.113.266-.25c.09-.656.54-1.134 1.342-1.134c.686 0 1.314.343 1.314 1.168c0 .635-.374.927-.965 1.371c-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486c.609-.463 1.244-.977 1.244-2.056c0-1.511-1.276-2.241-2.673-2.241c-1.267 0-2.655.59-2.75 2.286a.237.237 0 0 0 .241.247zm2.325 6.443c.61 0 1.029-.394 1.029-.927c0-.552-.42-.94-1.029-.94c-.584 0-1.009.388-1.009.94c0 .533.425.927 1.01.927z"
              ></path>
            </svg>
            <span>{status}</span>
          </>
        );
    }
  };

  getGenderIcon = (gender) => {
    switch (gender) {
      case "male":
        return (
          <>
            <svg width="1em" height="1em" viewBox="0 0 256 256">
              <path
                fill="currentColor"
                d="M216 32h-48a8 8 0 0 0 0 16h28.7l-42.1 42.1a80 80 0 1 0 11.3 11.3L208 59.3V88a8 8 0 0 0 16 0V40a8 8 0 0 0-8-8Zm-66.7 165.3a64 64 0 1 1 0-90.6a64.3 64.3 0 0 1 0 90.6Z"
              ></path>
            </svg>
            <span>{gender}</span>
          </>
        );
      case "female":
        return (
          <>
            <svg width="1em" height="1em" viewBox="0 0 256 256">
              <path
                fill="currentColor"
                d="M208 96a80 80 0 1 0-88 79.6V200H88a8 8 0 0 0 0 16h32v24a8 8 0 0 0 16 0v-24h32a8 8 0 0 0 0-16h-32v-24.4A80.1 80.1 0 0 0 208 96ZM64 96a64 64 0 1 1 64 64a64.1 64.1 0 0 1-64-64Z"
              ></path>
            </svg>
            <span>{gender}</span>
          </>
        );
      case "non_binary":
        return (
          <>
            <svg width="1em" height="1em" viewBox="0 0 256 256">
              <path
                fill="currentColor"
                d="M136 88.4V59l23.3 15.6a7.2 7.2 0 0 0 4.4 1.4a8 8 0 0 0 4.5-14.6L142.3 44l25.9-17.4a8 8 0 1 0-8.9-13.2l-31.3 21l-31.3-21a8 8 0 1 0-8.9 13.2L113.7 44L87.8 61.4A8 8 0 0 0 92.3 76a7.2 7.2 0 0 0 4.4-1.4L120 59v29.4a76 76 0 1 0 16 0ZM128 224a60 60 0 1 1 60-60a60 60 0 0 1-60 60Z"
              ></path>
            </svg>
            <span>{gender}</span>
          </>
        );
      case "neutral":
        return (
          <>
            <svg width="1em" height="1em" viewBox="0 0 256 256">
              <path
                fill="currentColor"
                d="M208 104a80 80 0 1 0-88 79.6V232a8 8 0 0 0 16 0v-48.4a80.1 80.1 0 0 0 72-79.6Zm-80 64a64 64 0 1 1 64-64a64.1 64.1 0 0 1-64 64Z"
              ></path>
            </svg>
            <span>{gender}</span>
          </>
        );
      default:
        return (
          <>
            <span>{gender}</span>
          </>
        );
    }
  };

  renderPayFeeInfo = () => {
    return (
      <>
        <div className="gatekeeper-container">
          <h1 style={{ textAlign: "center", width: "100%" }}>
            {this.state.applicantOffline.applicant_givenname}{" "}
            {this.state.applicantOffline.applicant_familyname}
          </h1>
          {this.state.applicantOnline && (
            <h1 style={{ textAlign: "center", width: "100%" }}>
              {this.state.applicantOnline.status}
            </h1>
          )}
          <h1 style={{ textAlign: "center", width: "100%" }}>
            {this.state.entranceFee} EUR
          </h1>
        </div>
        <div className="gatekeeper-container__footer">
          <Container>
            <Row>
              <Col xs={12}>
                <Button
                  color={"primary"}
                  onClick={() => this.setState({ entranceFee: 0 })}
                  className="btn btn-primary admit"
                >
                  BEZAHLT
                </Button>
              </Col>
            </Row>
          </Container>
        </div>
      </>
    );
  };

  renderAdmissionChoice = () => {
    return (
      <>
        <div className="gatekeeper-container">
          <Container>
            <Row>
              <Col
                sm="12"
                md={{
                  offset: 2,
                  size: 8,
                }}
              >
                <div className="scanned-person">
                  <div className="scanned-person__label">Status:</div>
                  {this.state.applicantOnline ? (
                    <h1 className="scanned-person__status">
                      {this.getStatusIcon(this.state.applicantOnline.status)}
                    </h1>
                  ) : (
                    ""
                  )}
                  <div className="scanned-person__label">Person:</div>
                  <h2 className="scanned-person__gender">
                    <div className="scanned-person__sublabel">Gender:</div>
                    {this.getGenderIcon(
                      this.state.applicantOffline.applicant_gender
                    )}
                  </h2>
                  <h3 className="scanned-person__name">
                    <div className="scanned-person__sublabel">Firstname:</div>
                    {this.state.applicantOffline.applicant_givenname}
                    <div className="scanned-person__sublabel">Lastname:</div>
                    {this.state.applicantOffline.applicant_familyname}
                  </h3>
                  <div className="scanned-person__group">
                    <div className="scanned-person__sublabel">Group:</div>
                    {this.state.applicantOnline &&
                      this.state.applicantOnline.group_name}
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
          <Container>
            <Row>
              <Col xs={6}>
                <ButtonDropdown
                  isOpen={this.state.showChoicesForRejection}
                  toggle={() =>
                    this.setState({
                      showChoicesForRejection:
                        !this.state.showChoicesForRejection,
                    })
                  }
                >
                  <DropdownToggle caret>Verweigerungsgrund</DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem
                      onClick={() => this.rejectApplicant("Dresscode")}
                    >
                      Dresscode nicht eingehalten
                    </DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem
                      onClick={() => this.rejectApplicant("Falsche Identität")}
                    >
                      Falsche Identität
                    </DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem
                      onClick={() =>
                        this.rejectApplicant("Falsches Gender angegeben")
                      }
                    >
                      Falsches Gender angegeben.
                    </DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem onClick={() => this.rejectApplicant("Creep")}>
                      Creep
                    </DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem onClick={() => this.rejectApplicant()}>
                      Anderer Grund...
                    </DropdownItem>
                  </DropdownMenu>
                </ButtonDropdown>
              </Col>
            </Row>
          </Container>
          <Container className={"status"}>
            <Row>
              <Col
                sm="12"
                md={{
                  offset: 2,
                  size: 8,
                }}
              >
                <h4>Status Erklärung</h4>
                <ul>
                  <li>
                    <span className="status-label">accepted</span>
                    <div className="status-value">
                      Bewerbung wurde angenommen, Mail mit Kauflink versendet
                    </div>
                  </li>
                  <li>
                    <span className="status-label">rejected</span>
                    <div className="status-value">
                      Der Club-Einlass wurde verwehrt, trotz validem Ticket
                    </div>
                  </li>
                  <li>
                    <span className="status-label">admitted</span>
                    <div className="status-value">Darf in den Club</div>
                  </li>
                </ul>
              </Col>
            </Row>
          </Container>
          <div className="gatekeeper-container__footer">
            <Container>
              <Row>
                <Col xs={6}>
                  <Button
                    color={"primary"}
                    onClick={this.admitApplicant}
                    className="btn btn-primary admit"
                  >
                    EINLASS OK
                  </Button>
                </Col>
                <Col xs={6}>
                  <Button
                    className="new-scan btn btn-secondary"
                    onClick={(e) => this.initState()}
                  >
                    New Scan
                  </Button>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </>
    );
  };

  renderGuestInfo = () => {
    return (
      <>
        <div className="gatekeeper-container">
          <Container>
            <Row>
              <Col
                sm="12"
                md={{
                  offset: 2,
                  size: 8,
                }}
              >
                <div className="scanned-person">
                  <div className="scanned-person__label">Person:</div>
                  <h3 className="scanned-person__name">
                    <div className="scanned-person__sublabel">Name:</div>
                    {this.state.applicantOffline.applicant_givenname}
                  </h3>
                </div>
              </Col>
            </Row>
          </Container>
          <Container className={"status"}>
            <Row>
              <Col
                sm="12"
                md={{
                  offset: 2,
                  size: 8,
                }}
              >
                <h4>Gender</h4>
                <h2>
                  <input
                    type="radio"
                    name="gender"
                    id="male"
                    onChange={() => this.setState({ observedGender: "male" })}
                  />
                  <label for="male">{this.getGenderIcon("male")}</label>
                </h2>
                <h2>
                  <input
                    type="radio"
                    name="gender"
                    id="female"
                    onChange={() => this.setState({ observedGender: "female" })}
                  />
                  <label for="female">{this.getGenderIcon("female")}</label>
                </h2>
                <h2>
                  <input
                    type="radio"
                    name="gender"
                    id="nonbinary"
                    onChange={() =>
                      this.setState({ observedGender: "non_binary" })
                    }
                  />
                  <label for="nonbinary">
                    {this.getGenderIcon("non_binary")}
                  </label>
                </h2>
              </Col>
            </Row>
          </Container>
          <div className="gatekeeper-container__footer">
            <Container>
              <Row>
                <Col xs={6}>
                  {this.state.observedGender && (
                    <Button
                      color={"primary"}
                      onClick={this.admitPretixOnly}
                      className="btn btn-primary admit"
                    >
                      EINLASS OK
                    </Button>
                  )}
                </Col>
                <Col xs={6}>
                  <Button
                    className="new-scan btn btn-secondary"
                    onClick={(e) => this.initState()}
                  >
                    New Scan
                  </Button>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </>
    );
  };

  renderQrReader = () => (
    <div>
      <QrReader
        delay={300}
        onError={this.handleError}
        onScan={this.handleScan}
        style={{ width: "100%", maxWidth: "500px", maxHeight: "500px" }}
      />
      <div
        style={{ backgroundColor: "white", color: "black", padding: "20px" }}
      >
        <pre>{this.state.qrContent}</pre>
      </div>
    </div>
  );
}

export default withRouter(Gatekeeper);
