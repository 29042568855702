import React from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Container, Progress, Row } from 'reactstrap';

const PageFooter = (props) => {
  return (
    <footer className={'page-footer'}>
      <Container>
        <Row>
          <Col
            sm="12"
            md={{
              offset: 2,
              size: 8
            }}>
            <div className="page-footer__navigation">
              <a onClick={props.onClickPrev ? props.onClickPrev : () => window.history.go(-1)} href="#" className="page-footer__prev-link" title="Gehe zum vorherigen Schritt">{props.lang === "de" ? "Zurück": "Back"}</a>
              <Button disabled={props.onClickNextCondition} onClick={props.onClickNext}  className="page-footer__next-link" color="primary">{props.lang === "de" ? "Weiter": "Next"}</Button>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

PageFooter.propTypes = {
  onClickPrev: PropTypes.func,
  onClickNextCondition: PropTypes.bool,
  onClickNext: PropTypes.func.isRequired
};

export default PageFooter;
