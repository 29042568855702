import React, { Component } from 'react';
import App from '../App';
import {
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  Badge,
  Table,
  Button,
  Container,
} from 'reactstrap';
import PageHead from './PageHead/PageHead';
import { Redirect } from 'react-router-dom';

export class Guestlist extends Component {

  state = {
    guestlist: null,
    selected_id: undefined,
    eventId: undefined,
    searchData: null,
  }

  constructor (props) {
    super(props);
    console.log("constructed Guestlist (props)", props);

    let eventId = props.match.params.eventId;
    if (!eventId)
      throw Error("Guestlist.js: eventItem ID not defined!");

    this.state = {
      eventId: eventId,
    }
  }

  componentDidMount = () => {

    if (window.location.hash)
      this.setState({ selected_id: window.location.hash.substr(1) });

    console.log("hash selector:", window.location.hash);

    fetch(
      process.env.REACT_APP_API_BASE_URL +
        "/api/events/" +
        this.state.eventId +
        "/guestlist"
    )
      .then((res) => res.json())
      .then((json) =>
        this.setState({
          guestlist: json,
          searchData: json,
        })
      );


  }

  searchItem = (query) => {
    const originalGuestlist = this.state.guestlist;
    if (!query) {
      this.setState({ searchData: originalGuestlist })
      return;
    }
    query = query.toLowerCase();

    const finalResult = [];

    originalGuestlist.forEach((item) => {
      if (
        item.response_id.toLowerCase().indexOf(query) !== -1
      ) {
        finalResult.push(item);
      }
    });
    this.setState({ searchData: finalResult });
  }

  getStatusColor = (status) => {
    switch (status) {
      case 'pending':
        return 'secondary'
      case 'accepted':
        return 'green'
      case 'evaluated':
        return 'orange'
      case 'rejected':
        return 'red'
      default:
        return 'secondary'
    }
  }

  getStatusTitle = (status) => {
    switch (status) {
      case 'pending':
        return 'Bewerbung wurde noch nicht angesehen'
      case 'accepted':
        return 'Bewerbung wurde angenommen, Mail mit Kauflink versendet'
      case 'evaluated':
        return 'Bewerbung bereits bewertet'
      case 'rejected':
        return 'Der Club-Einlass wurde verwehrt, trotz validem Ticket'
      case 'expelled':
        return 'Rauswurf aus dem Club'
      case 'admitted':
        return 'Ist im Club'
      default:
        return;
    }
  }

    render() {

        if (this.state.selected_id)
            return <Redirect to={"/events/" + this.state.eventId + "/applications/" + this.state.selected_id} />;

    return <>
      <PageHead lang={this.state.language} title={'Gästeliste'} titleEn={"Guestlist"}
                introText={'Wir werten laufend die Ergebnisse aus. Hier kannst du nachschauen, ob du die benötigte Punktzahl erreichen konntest. Falls nicht, keine Sorge! Wir öffnen um 22 Uhr auch die Abendkassa und lernen dich gegebenfalls persönlich kennen.'}
                introTextEn={"We are constantly evaluating the results. Here you can check if you were able to achieve the required score. If not, don't worry! We will also open the box office at 10pm and get to know you personally if necessary."}/>
      <Container className="guestlist">
        <Row>
          <Col
            sm="12"
            md={{
              offset: 2,
              size: 8
            }}>
            {this.state.guestlist && <>
              <Table className="guestlist__table" dark style={{ backgroundColor: '100%' }}>
                <thead>
                <tr>
                  <th>ID</th>
                  <th>Status</th>
                </tr>
                </thead>
                <tbody>

                {this.state.guestlist.map(e =>
                  <tr key={e.response_id} id={e.response_id}
                      className={this.state.selected_id === e.response_id ? ' is-selected' : ''}>
                    <td className="guestlist__item--id">{e.response_id}</td>
                    <td className="guestlist__item--status">

                      <Badge
                        color={this.getStatusColor(e.status)}
                        pill
                        title={this.getStatusTitle(e.status)}
                      >
                        {e.status}
                      </Badge>

                    </td>
                  </tr>
                )}
                </tbody>
              </Table>
            </>}
          </Col>
        </Row>
      </Container>
    </>;
  }

}
