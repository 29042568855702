import React, { Component } from "react";
import { Row, Col, Button } from "reactstrap";
import App from "../../App";
import { withRouter } from "react-router-dom";

class Login extends Component {

  state = {
    email: '',
    pwd: '',
    failedLogin: false,
    loginResponseMessage: ''
  }

    handleAuthorization = async () => {

        let email = this.state.email;
        let hashedPwd = App.makeSHA256Hash(this.state.pwd);

        console.log("trying to log in (email, hashedPwd)", email, hashedPwd);

        fetch(
          process.env.REACT_APP_API_BASE_URL +
            "/api/users/" +
            this.state.email +
            "/authorize?pwd=" +
            hashedPwd
        ).then((res) => {
          console.log("res-login", res);

          /*if (!res.ok) {
                  res.text().then(loginResponseMessage =>
                    this.setState({
                      failedLogin: true,
                      loginResponseMessage
                    }, () => alert("Hm... 🤔 Login hat nicht geklappt:\n" + loginResponseMessage)));
                } else {
                  console.log(this.props);
                  //res.json().then(session => this.props.setSession(session)).then(() => this.props.history.push('/regulars/profile'));
                }*/
        });
    }

  render () {

    return <>
      <Row>
        <Col sm={12}>
          <div className="login-form">
            <h1 className="text-lg font-bold">Login</h1>
            <form className="flex flex-col mt-4">
              <input
                type="email"
                name="email"
                className="input-email"
                placeholder="E-Mail Addresse" value={this.state.email}
                onChange={e => this.setState({ email: e.target.value })}/>

              <input
                type="password" value={this.state.pwd}
                onChange={e => this.setState({ pwd: e.target.value })}
                name="password"
                className="input-password"
                placeholder="Password"
              />
              <button disabled={!this.state.email || !this.state.pwd}
                      onClick={this.handleAuthorization}
                      className="login-button"
              >
                Login
              </button>
            </form>
          </div>
        </Col>
      </Row>
    </>;
  }
}

export default withRouter(Login);