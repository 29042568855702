import React from 'react';
import PropTypes from 'prop-types';
import { Col, Container, Progress, Row } from 'reactstrap';

const PageHead = (props) => {
  return (
    <header className={'page-header'}>
      <Container>
        <Row>
          <Col
            sm="11"
            md={{
              offset: 2,
              size: 8
            }}
          >
            {props.progressValue ?
              <div className="page-head__progress">
                <Progress className="page-head__progress-wrapper" barClassName="page-header__progress-bar" value={props.progressValue}>{props.progressLabel ? props.progressLabel : props.progressValue}</Progress>
              </div>
              : ''}
            <div className="page-head__title">
              <h1 dangerouslySetInnerHTML={{ __html: props.lang === "de" ? props.title : props.titleEn ? props.titleEn : props.title }}></h1>
            </div>
            {props.introText || props.children ?
              <div className="page-head__intro">
                {props.children ? props.children : <p>{props.lang === "de" ? props.introText : props.introTextEn ? props.introTextEn : props.introText}</p>}
              </div>
              : ''}
            {props.cta ?
              <div className="page-head__cta">
                {props.cta}
              </div>
              : ''}
          </Col>
        </Row>
      </Container>
    </header>
  );
};

PageHead.propTypes = {
  title: PropTypes.string.isRequired,
  introText: PropTypes.node,
  cta: PropTypes.element,
  progressValue: PropTypes.number,
  lang: PropTypes.string,
  progressLabel: PropTypes.string
};

export default PageHead;
