import React, { Component } from 'react';
export class Layout extends Component {

  render () {
    return (
      <>
        <div className="body-container">
          {this.props.children}
        </div>
      </>
    );
  }
}
