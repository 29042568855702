import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { Button, Col, Row, Table, Input, Modal, ModalHeader, ModalBody, ModalFooter, DropdownItem, DropdownToggle, DropdownMenu, ButtonDropdown } from 'reactstrap';
import Loading from './Loading/Loading';

class Evaluate extends Component {
  state = {
    guestlist: null,
    selected: null,
    eventId: undefined,
    showChoicesForStatus: false,
    pointsForMotivation: 0,
    processing: false,
  };

  constructor(props) {
    super(props);
    console.log("constructed Evaluate (props)", props);

    let eventId = props.match.params.eventId;
    if (!eventId) throw Error("Evaluate.js: eventItem ID not defined!");

    this.state.eventId = eventId;
  }

  componentDidMount = () => this.loadGuestList();

  setStatusAndCloseModal = (status) => {
    let score = this.state.pointsForMotivation;
    let applicationId = this.state.selected.response_id;

    this.setState({
      processing: applicationId,
      selected: null,
      pointsForMotivation: 0,
    });

    fetch(
      process.env.REACT_APP_API_BASE_URL +
        `/api/events/${this.state.eventId}/applications/${applicationId}/setstatus?status=${status}&score=${score}`
    )
      .then((res) => res.json())
      .then((json) => {
        if (!json) {
          alert("failed");
          throw new Error("setStatusAndCloseModal() fetch Api failed");
        }

        this.loadGuestList();
        this.setState({ processing: false });
      });
  };

  loadGuestList = () => {
    fetch(
      process.env.REACT_APP_API_BASE_URL +
        "/api/events/" +
        this.state.eventId +
        "/evaluationlist"
    )
      .then((res) => res.json())
      .then((json) => this.setState({ guestlist: json }));
  };

  setForEvaluation = (application) => {
    this.setState({
      selected: application,
      pointsForMotivation: application.points_statement,
    });
  };

  render() {
    if (!this.state.guestlist) return <Loading />;

    let pending = this.state.guestlist.filter((a) => a.status === "pending");
    let evaluated = this.state.guestlist.filter(
      (a) => a.status === "evaluated"
    );
    let accepted = this.state.guestlist.filter((a) => a.status === "accepted");
    let rejected = this.state.guestlist.filter((a) => a.status === "rejected");

    return (
      <Row id="evaluate">
        <Modal isOpen={this.state.selected && true}>
          <ModalHeader
            toggle={(e) =>
              this.setState({ selected: null, pointsForMotivation: 0 })
            }
          ></ModalHeader>
          <ModalBody>
            <ul>
              <li>
                <strong>Punkte (auto): </strong>
                {this.state.selected && this.state.selected.points}
              </li>
              <li>
                <strong>Gender: </strong>
                {this.state.selected && this.state.selected.gender}
              </li>
              <li>
                <strong>Motivation: </strong>"
                {this.state.selected &&
                  this.state.selected.statement_of_motivation}
                "
              </li>
            </ul>
            <strong>Punkte für Motivationsschreiben:</strong>
            <Input
              type="number"
              value={this.state.pointsForMotivation}
              onChange={(e) =>
                this.setState({ pointsForMotivation: e.target.value })
              }
            />
          </ModalBody>
          <ModalFooter>
            <ButtonDropdown
              isOpen={this.state.showChoicesForStatus}
              toggle={() =>
                this.setState({
                  showChoicesForStatus: !this.state.showChoicesForStatus,
                })
              }
            >
              <DropdownToggle caret>set status</DropdownToggle>
              <DropdownMenu>
                <DropdownItem
                  onClick={() => this.setStatusAndCloseModal("evaluated")}
                >
                  evaluated
                </DropdownItem>
                {this.state.selected &&
                  this.state.selected.status !== "pending" && (
                    <>
                      <DropdownItem divider />
                      <DropdownItem
                        onClick={() => this.setStatusAndCloseModal("accepted")}
                      >
                        accepted
                      </DropdownItem>
                    </>
                  )}
              </DropdownMenu>
            </ButtonDropdown>
          </ModalFooter>
        </Modal>
        <Col sm={12}>
          <h1 className="centerText">Guestlist</h1>
          {this.state.guestlist && (
            <Table style={{ width: "100%" }}>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Gender</th>
                  <th>Punkte</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td colSpan="5">PENDING</td>
                </tr>
                {pending.map((a) => (
                  <tr key={a.response_id} id={a.response_id}>
                    <td>{a.response_id}</td>
                    <td>{a.gender}</td>
                    <td>{a.points_auto + a.points_statement}</td>
                    <td>{a.status}</td>
                    <td>
                      <Button onClick={() => this.setForEvaluation(a)}>
                        edit
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
              <tbody>
                <tr>
                  <td colSpan="5">EVALUATED</td>
                </tr>
                {evaluated.map((a) => (
                  <tr key={a.response_id} id={a.response_id}>
                    <td>{a.response_id}</td>
                    <td>{a.gender}</td>
                    <td>{a.points_auto + a.points_statement}</td>
                    <td>{a.status}</td>
                    <td>
                      <Button onClick={() => this.setForEvaluation(a)}>
                        edit
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
              <tbody>
                <tr>
                  <td colSpan="4">ACCEPTED</td>
                </tr>
                {accepted.map((a) => (
                  <tr key={a.response_id} id={a.response_id}>
                    <td>{a.response_id}</td>
                    <td>{a.gender}</td>
                    <td>{a.points_auto + a.points_statement}</td>
                    <td>{a.status}</td>
                    <td>
                      <Button onClick={() => this.setForEvaluation(a)}>
                        edit
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
              <tbody>
                <tr>
                  <td colSpan="4">REJECTED</td>
                </tr>
                {rejected.map((a) => (
                  <tr key={a.response_id} id={a.response_id}>
                    <td>{a.response_id}</td>
                    <td>{a.gender}</td>
                    <td>{a.points_auto + a.points_statement}</td>
                    <td>{a.status}</td>
                    <td>
                      <Button onClick={() => this.setForEvaluation(a)}>
                        edit
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </Col>
      </Row>
    );
  }
}

export default withRouter(Evaluate)
