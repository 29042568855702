import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import QRCode from 'qrcode.react'
import App from '../App'

class TestFeatures extends Component {

    state = {
        fetch: undefined,
        qrCode: true,
        hash: undefined,
        message: undefined
    }

    constructor(props) {
        super(props);
    }

    componentDidMount = () => {

        try {

            fetch(process.env.REACT_APP_API_BASE_URL + "/api/urlroot")
              .then((res) => res.text())
              .then((root) => {
                this.setState({ fetch: root });
              });

            let hash = App.makeSHA256Hash("test");
            this.setState({ hash });
        }
        catch (error) {
            this.setState({ message: error.message });
        }
    }

    render = () => {

        let v = "2022-02-26-A";

        try {
            return <>
                <h1>{v} :-)</h1>
                <ul>
                    <li>fetch: {this.state.fetch}</li>
                    <li>hash: {this.state.hash}</li>
                    <li>qr: {this.state.qrCode}</li>
                </ul>
                <QRCode value=":-)" />
            </>;
        }
        catch (error) {
            return <>
                <h1>{v} :-(</h1>
                <p>{error.message}</p>
            </>;
        }
    }
}

export default withRouter(TestFeatures);