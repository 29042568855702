import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardImg,
  CardSubtitle,
  CardText,
  CardTitle
} from 'reactstrap';

function EventCard ( eventItem  ) {
  const cardClick = (e) => {
    e.preventDefault();
    console.log("EventCard.js:8 cardClick()", e);

    //this.eventItem.history.push('/events/' + eventItem.event_id + '/questionnaire')
  }
  return (
    <div>
      <Card onClick={cardClick} className="event-card"
      >
        <CardImg
          alt="Card image cap"
          src="https://picsum.photos/318/180"
          top
          width="100%"
        />
        <CardBody>

          <CardTitle tag="h3">
            {eventItem.event_title}
          </CardTitle>
          <CardSubtitle
            className="mb-2"
            tag="h6"
          >
            {eventItem.event_date}
          </CardSubtitle>
          <CardText>
            {eventItem.event_description}
          </CardText>

        </CardBody>

        <CardFooter>

          <ul className="datalist">
            <li>Anmeldung offen bis: {eventItem.registration.open}</li>
            <li>Verfügbare Gästelistenplätze: {eventItem.guestlist_capacity}</li>
          </ul>

        </CardFooter>
      </Card>
    </div>
  );
}

EventCard.propTypes = {
  event: PropTypes.object
};

export default EventCard;
