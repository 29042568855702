import React, { Component } from 'react'
import { withRouter } from "react-router-dom"
import QrReader from 'react-qr-reader'
import {
  Button,
  Container,
  Row,
  Col,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem, Badge, Alert
} from 'reactstrap'
import App from '../App'

class BoxInfo extends Component {
  state = {
    eventId: "gw2",
    audienceCount: null,
    pctMale: null,
    pctFemale: null,
    deltaMale: 0,
  };

  componentDidMount = () => {
    this.obtainAudienceCount();
    setInterval(() => this.obtainAudienceCount(), 60000);
  };

  obtainAudienceCount = () =>
    fetch(
      process.env.REACT_APP_API_BASE_URL +
        "/api/events/" +
        this.state.eventId +
        "/audiencecount"
    )
      .then((res) => res.json())
      .then((json) => {
        let audienceCount = json;

        let pctMale = Math.round(
          (audienceCount.male / audienceCount.total) * 100
        );
        let pctFemale = Math.round(
          (audienceCount.female / audienceCount.total) * 100
        );

        let maxPctMale = 45;
        let amountMaleAtMaxPct = (audienceCount.total / 100) * maxPctMale;
        let deltaMale = audienceCount.male - amountMaleAtMaxPct;

        this.setState(
          {
            audienceCount: json,
            pctMale,
            pctFemale,
            deltaMale,
          },
          () => console.log(this.state)
        );
      });

  addParticipant = (gender) =>
    fetch(
      process.env.REACT_APP_API_BASE_URL +
        `/api/events/${this.state.eventId}/registerboxofficeentry?gender=${gender}`
    ).then((res) => {
      if (res.ok) this.obtainAudienceCount();
      else alert("failed to add participant - server issue?");
    });

  isEinlassStoppM = () =>
    this.state.audienceCount.male > this.state.audienceCount.female ||
    this.state.deltaMale > 0;

  render = () =>
    this.state.audienceCount && (
      <>
        <div className={"boxinfo-container"}>
          <Container>
            <Row>
              <Col
                sm="12"
                md={{
                  offset: 2,
                  size: 8,
                }}
              >
                <div className="guest-count">
                  <div>
                    <Badge color="success" pill>
                      {this.state.audienceCount.total}
                    </Badge>
                  </div>
                  <span>Gäste im Club</span>
                </div>

                <div className="ratio-container">
                  <div className="ratio ratio--male">
                    <div className="ratio-value">{this.state.pctMale}</div>
                    <div className="ratio-icon">
                      {this.getGenderIcon("male")}
                    </div>
                  </div>
                  <div className="ratio ratio-divider">
                    <div className="ratio-value">:</div>
                  </div>
                  <div className="ratio ratio--female">
                    <div className="ratio-value">{this.state.pctFemale}</div>
                    <div className="ratio-icon">
                      {this.getGenderIcon("female")}
                    </div>
                  </div>
                </div>

                {this.isEinlassStoppM() && (
                  <div className={"einlassstop"}>
                    <Alert color="danger">
                      <svg width="1em" height="1em" viewBox="0 0 16 16">
                        <path
                          fill="currentColor"
                          d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2a1 1 0 0 1 0-2z"
                        ></path>
                      </svg>
                      <span>
                        EINLASSSTOP <strong>MALE</strong>
                      </span>
                    </Alert>
                  </div>
                )}
                <div className="boxinfo-container__footer">
                  <Button
                    className="btn-male"
                    onClick={(e) => this.addParticipant("male")}
                  >
                    <span class={"btn-up"}>+1</span>{" "}
                    {this.getGenderIcon("male")}
                  </Button>
                  <Button
                    className="btn-female"
                    onClick={(e) => this.addParticipant("female")}
                  >
                    <span className={"btn-up"}>+1</span>{" "}
                    {this.getGenderIcon("female")}
                  </Button>
                  <Button
                    className="btn-non-binary"
                    onClick={(e) => this.addParticipant("non_binary")}
                  >
                    <span className={"btn-up"}>+1</span>{" "}
                    {this.getGenderIcon("non_binary")}
                  </Button>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </>
    );

  getGenderIcon = (gender) => {
    switch (gender) {
      case "male":
        return (
          <>
            <svg width="1em" height="1em" viewBox="0 0 256 256">
              <path
                fill="currentColor"
                d="M216 32h-48a8 8 0 0 0 0 16h28.7l-42.1 42.1a80 80 0 1 0 11.3 11.3L208 59.3V88a8 8 0 0 0 16 0V40a8 8 0 0 0-8-8Zm-66.7 165.3a64 64 0 1 1 0-90.6a64.3 64.3 0 0 1 0 90.6Z"
              ></path>
            </svg>
            <span>{gender}</span>
          </>
        );
      case "female":
        return (
          <>
            <svg width="1em" height="1em" viewBox="0 0 256 256">
              <path
                fill="currentColor"
                d="M208 96a80 80 0 1 0-88 79.6V200H88a8 8 0 0 0 0 16h32v24a8 8 0 0 0 16 0v-24h32a8 8 0 0 0 0-16h-32v-24.4A80.1 80.1 0 0 0 208 96ZM64 96a64 64 0 1 1 64 64a64.1 64.1 0 0 1-64-64Z"
              ></path>
            </svg>
            <span>{gender}</span>
          </>
        );
      case "non_binary":
        return (
          <>
            <svg width="1em" height="1em" viewBox="0 0 256 256">
              <path
                fill="currentColor"
                d="M136 88.4V59l23.3 15.6a7.2 7.2 0 0 0 4.4 1.4a8 8 0 0 0 4.5-14.6L142.3 44l25.9-17.4a8 8 0 1 0-8.9-13.2l-31.3 21l-31.3-21a8 8 0 1 0-8.9 13.2L113.7 44L87.8 61.4A8 8 0 0 0 92.3 76a7.2 7.2 0 0 0 4.4-1.4L120 59v29.4a76 76 0 1 0 16 0ZM128 224a60 60 0 1 1 60-60a60 60 0 0 1-60 60Z"
              ></path>
            </svg>
            <span>{gender}</span>
          </>
        );
      case "neutral":
        return (
          <>
            <svg width="1em" height="1em" viewBox="0 0 256 256">
              <path
                fill="currentColor"
                d="M208 104a80 80 0 1 0-88 79.6V232a8 8 0 0 0 16 0v-48.4a80.1 80.1 0 0 0 72-79.6Zm-80 64a64 64 0 1 1 64-64a64.1 64.1 0 0 1-64 64Z"
              ></path>
            </svg>
            <span>{gender}</span>
          </>
        );
      default:
        return (
          <>
            <span>{gender}</span>
          </>
        );
    }
  };
}

export default withRouter(BoxInfo);
