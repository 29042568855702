import React, { Component } from 'react'
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText, Progress, Spinner,
} from 'reactstrap'
import { withRouter } from 'react-router-dom'
import {} from "bootstrap"
import '../questionnaire.css'
import App from '../App'
import Loading from './Loading/Loading';
import login from './Login/Login';
import PageFooter from './PageFooter/PageFooter';
import PageHead from './PageHead/PageHead';
import QGender from './Questionnaire/Gender/qGender';
import QIntro from './Questionnaire/Intro/qIntro';
import { BiometricIdInfo } from "./Questionnaire/BiometricIdInfo";

class Questionnaire extends Component {
  static displayName = "Questionnaire";

  url = undefined;

  state = {
    eventId: undefined,
    urlRoot: undefined,
    registration_close: undefined,

    given_name: "",
    biometric_id: "",
    family_name: "",
    email: "",
    group_name: undefined,
    group_status: null,
    lang: undefined,
    consent_to_publish: false,
    newsletter_optin: false,

    gender: undefined,
    age_group: undefined,

    hash: undefined,
    guid_response: undefined,

    questionnaire: null,
    statement_of_motivation: "",
    feedbackTxt: undefined,
    response: null,

    hasChosenLanguage: false,
    hasReadIntro: false,
    selectedQuestionCategory: null,
    selectedQuestionIndex: 0,
    hasConfirmedAnswers: false,
    hasConfirmedGender: false,
    hasConfirmedAgeGroup: false,
    hasConfirmedLetter: false,
    isSubmittingAnswers: false,
    hasSubmittedAnswers: false,

    isAdmin: false,
  };

  constructor(props) {
    super(props);
    console.log("constructed Questionnaire (props)", props);

    let eventId = props.match.params.eventId;
    if (!eventId) throw Error("Questionnaire.js: event ID not defined!");

    this.state.eventId = eventId;
    this.url = window.location.origin + window.location.pathname;
  }

  componentDidMount = () => {
    this.setState({ lang: this.props.state.language });
    console.log("current language - componentDidMount: ", this.state.lang);

    fetch(process.env.REACT_APP_API_BASE_URL + "/api/urlroot")
      .then((res) => res.text())
      .then((root) => {
        this.setState({ urlRoot: root });
        console.log("urlRoot:", root);
      });

    fetch(process.env.REACT_APP_API_BASE_URL + "/api/events")
      .then((res) => res.json())
      .then((events) => {
        let eventMeta = events.filter(
          (e) => e.event_id == this.state.eventId
        )[0];
        console.log("current event-meta: ", eventMeta);
        console.log("current event-registration: ", eventMeta.registration);
        this.setState({
          event: eventMeta,
          registration_close: eventMeta.registration.close,
        });
      });

    window.onpopstate = (s) => {
      if (!s.state) console.error("no state found :-(");
      else {
        console.log("reverting state to:", s.state);
        this.setState(s.state);
      }
    };
  };

  formatDate(date) {
    const localDate = new Date(date);
    const options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minutes: "2-digit",
      timeZone: "CET",
    };
    return localDate.toLocaleDateString("de-DE", options);
  }

  fetchQuestions = (lang) => {
    console.log("props", this.props);
    console.log("about to fetch questionnaire in language: " + lang);
    fetch(
      process.env.REACT_APP_API_BASE_URL +
        "/api/events/" +
        this.state.eventId +
        "/questionnaire?lang=" +
        lang
    )
      .then((res) => res.json())
      .then((json) => this.setState({ questionnaire: json.questionnaire }));
  };

  confirmLanguage = (e, lang, bool = true) => {
    window.history.pushState(this.state, "zk: language", this.url + "#intro");
    console.log("logged state: ", this.state);
    this.setState({ hasChosenLanguage: bool, lang }, () =>
      this.fetchQuestions(lang)
    );
  };

  confirmReadIntro = (e, bool = true) => {
    window.history.pushState(this.state, "zk: intro", this.url + "#gender");
    console.log("logged state: ", this.state);
    this.setState({ hasReadIntro: bool });
  };

  confirmGender = (e, gender, bool = true) => {
    window.history.pushState(this.state, "zk: gender", this.url + "#quiz");
    console.log("logged state: ", this.state);
    console.log("Questionnaire.js:101 confirmGender()", gender);
    this.setState({ hasConfirmedGender: bool, gender: gender });
  };
  confirmAge = (e, age_group, bool = true) => {
    window.history.pushState(this.state, "zk: age_group", this.url + "#quiz");
    console.log("logged state: ", this.state);
    console.log("Questionnaire.js:151 confirmAge()", age_group);
    this.setState({ hasConfirmedAgeGroup: bool, age_group: age_group });
  };

  confirmAnswers = (e, bool = true) => {
    window.history.pushState(
      this.state,
      "zk: fragen beantworten",
      this.url + "#letter"
    );
    console.log("logged state: ", this.state);
    window.scrollTo(0, 0);
    this.setState({ hasConfirmedAnswers: bool });
  };

  confirmLetterOfMotivation = (e, bool = true) => {
    window.history.pushState(this.state, "zk: letter of motivation");
    console.log("logged state: ", this.state);
    window.scrollTo(0, 0);
    this.setState({ hasConfirmedLetter: true });
  };

  renderGroupStatus = (status) => {
    switch (status) {
      case "NOTFOUND":
        return this.state.lang == "de"
          ? `⚠️ Gruppe <strong>${this.state.group_name}</strong> existiert nicht, wird also neu angelegt.`
          : `⚠️ Group <strong>${this.state.group_name}</strong> does not exist. It will be created.`;
      case "MAXMEMBERS":
        return this.state.lang == "de"
          ? `⛔ Gruppe <strong>${this.state.group_name}</strong> hat bereits 4 Mitglieder (Limit), du kannst ihr nicht joinen.`
          : `⛔ Group <strong>${this.state.group_name}</strong> has reached its limit of 4 members, you cannot join it.`;
      case "FOUND":
        return this.state.lang == "de"
          ? `✅ Gruppe <strong>${this.state.group_name}</strong> existiert und du kannst ihr joinen.`
          : `✅ Group <strong>${this.state.group_name}</strong> exists - you can join it.`;
      default:
        return;
    }
  };
  checkGroupName = async (group_name) => {
    this.setState({ group_name: group_name });

    let response = await fetch(
      process.env.REACT_APP_API_BASE_URL +
        "/api/events/" +
        this.state.eventId +
        "/groups/" +
        group_name
    );
    let allGroupMembers = await response.json();

    console.log("group members:", allGroupMembers);
    if (group_name.length > 0) {
      if (allGroupMembers.length === 0) {
        this.setState({ group_status: "NOTFOUND" });
      } else if (allGroupMembers.length >= 4) {
        this.setState({ group_status: "MAXMEMBERS" });
      } else if (allGroupMembers.length > 0) {
        this.setState({ group_status: "FOUND" });
      }
    } else {
      this.setState({ group_status: null, group_name: undefined });
    }
  };

  postResponsesToServer = async (e) => {
    if (this.state.group_status === "MAXMEMBERS") {
      return false;
    }

    window.history.pushState(this.state, "zk: personalien");
    console.log("logged state: ", this.state);

    let name = `${
      this.state.given_name
    } ${this.state.family_name.toUpperCase()}`;
    let hashed_name = App.makeSHA256Hash(name);

    let response = {
      hashed_name: hashed_name,
      gender: this.state.gender,
      age_group: this.state.age_group,
      group_name: this.state.group_name,
      consent_to_publish: this.state.consent_to_publish,

      contact_details: {
        given_name: this.state.given_name,
        biometric_id: this.state.biometric_id,
        family_name: this.state.family_name,
        email: this.state.email,
      },

      response: {
        statement_of_motivation: this.state.statement_of_motivation,
        responses_to_questions: this.state.questionnaire
          .map((category) =>
            category.questions.map((q) => {
              return {
                question_id: q.id,
                answers_ids: q.answers
                  .filter((a) => a.selected)
                  .map((a) => a.id),
              };
            })
          )
          .flat(),
      },
    };

    console.log("to be submitted:", response);
    this.setState({ response, isSubmittingAnswers: true });

    let responseSubmittedApplication = await fetch(
      process.env.REACT_APP_API_BASE_URL +
        "/api/events/" +
        this.state.eventId +
        "/application?lang=" +
        this.state.lang,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(response),
      }
    );

    let resultOfApplicationSubmission =
      await responseSubmittedApplication.json();

    console.log("response from server:", resultOfApplicationSubmission);

    if (resultOfApplicationSubmission.success === false) {
      alert(
        this.state.lang == "de"
          ? "Bitte e-Mail Adresse überprüfen!"
          : "Please check your e-Mail address!"
      );
      console.error(response);
      this.setState({ hasSubmittedAnswers: false, isSubmittingAnswers: false });
    } else if (
      resultOfApplicationSubmission.success &&
      resultOfApplicationSubmission.guid
    )
      this.setState({
        hasSubmittedAnswers: true,
        hash: hashed_name,
        guid_response: resultOfApplicationSubmission.guid,
        group_name: resultOfApplicationSubmission.group_name,
      });
    else {
      console.error("something seems fishy ... ", response);
      throw new Error("postResponseToServer", response);
    }

    // Sendinblue Newsletter
    if (this.state.newsletter_optin === true) {
      const options = {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "api-key": process.env.REACT_APP_SENDINBLUE_API_KEY,
        },
        body: JSON.stringify({
          attributes: {
            VORNAME: this.state.given_name,
            NACHNAME: this.state.family_name,
            OPT_IN: this.state.newsletter_optin,
          },
          email: this.state.email,
          includeListIds: [14], // ZK10 List - change if new event
          templateId: 1,
          redirectionUrl: "https://www.hausgemacht.org/newsletter/erfolg",
        }),
      };

      fetch(
        "https://api.sendinblue.com/v3/contacts/doubleOptinConfirmation",
        options
      )
        .then((response) => response.json())
        .then((response) => console.log(response))
        .catch((err) => console.error(err));
    }
  };

  goToPrevStep = () => {
    window.history.back();
  };

  render() {
    let lang = this.props.state.language;
    console.log("current language - render: ", lang);

    if (new Date(this.state.registration_close) < new Date()) {
      return (
        <>
          <PageHead
            title={"Keine Bewerbung mehr möglich"}
            introText={`Leider konntest du dich nur bis zum ${this.formatDate(
              this.state.registration_close
            )} bewerben.`}
          />
        </>
      );
    }

    console.log("Questionnaire.js:231 render()", this.state);
    if (new Date(this.state.registration_close) > new Date()) {
      if (!this.state.hasChosenLanguage) return this.renderChooseLanguage(lang);

      if (!this.state.questionnaire) return <Loading />;

      if (this.state.questionnaire && !this.state.questionnaire.length > 0)
        return <h1>problem :-(</h1>;

      if (!this.state.hasReadIntro) return this.renderIntro(lang);

      if (!this.state.hasConfirmedGender) return this.renderDefineGender(lang);

      if (!this.state.hasConfirmedAgeGroup)
        return this.renderDefineAgeGroup(lang);

      if (!this.state.hasConfirmedLetter)
        return this.renderEnterLetterOfMotivation(lang);

      if (!this.state.hasConfirmedAnswers) {
        let allAnswered = this.state.questionnaire.every((c) =>
          c.questions.every((q) => q.answers.some((a) => a.selected))
        );
        return this.renderFillQuestionnaire(lang, allAnswered);
      }

      if (!this.state.hasSubmittedAnswers) {
        if (!this.state.isSubmittingAnswers) return this.renderEnterName(lang);
        else return this.renderAnswersBeingSubmitted(lang);
      } else return this.renderShowQRCode(lang);
    }

    return <h1>uuups? wie konnte denn das passieren? ADMIN, hilfe bitte!</h1>;
  }

  renderChooseLanguage = (lang) => (
    <div className="choose-language">
      <PageHead
        title={"Spra&shy;che aus&shy;wäh&shy;len"}
        introText={
          "In welcher Sprache fühlst dich wohler den Prozess durchzuführen? — In which language do you feel more comfortable conducting the process?"
        }
      />
      <Container>
        <Row>
          <Col
            sm="12"
            md={{
              offset: 2,
              size: 8,
            }}
          >
            <p>Bitte Sprache auswählen // Please choose your language:</p>
            <div className="language-chooser">
              <div
                onClick={() => {
                  this.props.setLanguage("en");
                  this.setState({ lang: "en" });
                }}
                className={`language-choice language-choice--english${
                  lang === "en" ? " is-selected" : ""
                }`}
              >
                English
              </div>
              <div
                onClick={() => {
                  this.props.setLanguage("de");
                  this.setState({ lang: "de" });
                }}
                className={`language-choice language-choice--german${
                  lang === "de" ? " is-selected" : ""
                }`}
              >
                Deutsch
              </div>
            </div>
          </Col>
        </Row>
      </Container>

      <PageFooter
        lang={lang}
        onClickPrev={(e) => {
          console.log("Questionnaire.js:267 e()", e);
          e.preventDefault();
          window.location = "https://www.hausgemacht.org/zusammenkommen/";
        }}
        onClickNext={(e) => this.confirmLanguage(e, this.state.lang)}
      ></PageFooter>
    </div>
  );

  renderIntro = (lang) => (
    <div className="intro">
      <QIntro lang={lang} />
      <PageFooter
        lang={lang}
        onClickPrev={this.goToPrevStep}
        onClickNext={this.confirmReadIntro}
      ></PageFooter>
    </div>
  );

  renderDefineGender = (lang) => (
    <div className="question-gender">
      <PageHead
        lang={lang}
        title={"Iden&shy;ti&shy;fi&shy;ka&shy;ti&shy;on"}
        titleEn={"Iden&shy;ti&shy;fi&shy;ca&shy;ti&shy;on"}
        progressValue={25}
        progressLabel={"1/4"}
        introText={
          "Für eine harmonische Gruppendynamik setzen wir auf eine heterogene Partycrowd ohne Überrepräsentation. Aus diesem Grund: Wie identifizierst du dich?"
        }
        introTextEn={
          "For a harmonised group dynamic, we are setting up for a heterogeneous party crowd without over-representation. For this reason: How do you identify yourself?"
        }
      />

      <QGender
        lang={lang}
        handleClick={(gender) => {
          this.setState({ gender: gender });
        }}
        gender={this.state.gender}
      />

      <PageFooter
        lang={lang}
        onClickNext={(e) => {
          this.confirmGender(e, this.state.gender);
        }}
        onClickNextCondition={this.state.gender === undefined}
      ></PageFooter>
    </div>
  );
  onAgeSlider = (e) => {
    const value = e.target.value;
    const valuePrefix = "ag";
    let mergedValue;

    switch (value) {
      case "1":
        mergedValue = valuePrefix + "1820";
        break;
      case "2":
        mergedValue = valuePrefix + "2125";
        break;
      case "3":
        mergedValue = valuePrefix + "2630";
        break;
      case "4":
        mergedValue = valuePrefix + "3135";
        break;
      case "5":
        mergedValue = valuePrefix + "3645";
        break;
      case "6":
        mergedValue = valuePrefix + "46plus";
        break;
      default:
        mergedValue = valuePrefix + "";
    }
    if (e.target.parentNode.childNodes[1]) {
      e.target.parentNode.childNodes[1].remove();
    }

    this.setState({ age_group: mergedValue });
  };

  renderDefineAgeGroup = (lang) => (
    <div className="question-agegroup">
      <PageHead
        lang={lang}
        title={"Al&shy;ters&shy;grup&shy;pe"}
        titleEn={"Age Group"}
        introText={"Bitte wähle deine Altersgruppe aus."}
        introTextEn={"Please select your age group."}
      />
      <Container>
        <Row id="questionnaire">
          <Col sm={12}>
            <div className="agegroup_slider">
              <div className={"agegroup_slider-labels"}>
                <label
                  htmlFor={"age_group"}
                  className={`agegroup-label ${
                    this.state.age_group === "ag1820" ? "is-active" : ""
                  }`}
                >
                  18-20
                </label>
                <label
                  htmlFor={"age_group"}
                  className={`agegroup-label ${
                    this.state.age_group === "ag2125" ? "is-active" : ""
                  }`}
                >
                  21-25
                </label>
                <label
                  htmlFor={"age_group"}
                  className={`agegroup-label ${
                    this.state.age_group === "ag2630" ? "is-active" : ""
                  }`}
                >
                  26-30
                </label>
                <label
                  htmlFor={"age_group"}
                  className={`agegroup-label ${
                    this.state.age_group === "ag3135" ? "is-active" : ""
                  }`}
                >
                  31-35
                </label>
                <label
                  htmlFor={"age_group"}
                  className={`agegroup-label ${
                    this.state.age_group === "ag3645" ? "is-active" : ""
                  }`}
                >
                  36-45
                </label>
                <label
                  htmlFor={"age_group"}
                  className={`agegroup-label ${
                    this.state.age_group === "ag46plus" ? "is-active" : ""
                  }`}
                >
                  46+
                </label>
              </div>
              <div className="agegroup_slider-slider">
                <input
                  type="range"
                  name="age_group"
                  min="1"
                  max="6"
                  defaultValue={3}
                  onChange={this.onAgeSlider}
                  className="agegroup_slider-input"
                  id="age_group"
                />
                <span>Drag me ↔</span>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <PageFooter
        lang={lang}
        onClickNext={(e) => {
          this.confirmAge(e, this.state.age_group);
        }}
        onClickNextCondition={this.state.age_group === undefined}
      />
    </div>
  );

  setQuestionIndex = (j) => {
    this.setState({
      selectedQuestionIndex: j,
    });
  };
  renderFillQuestionnaire = (lang, allAnswered) => (
    <div className="question-questionnaire">
      <PageHead
        lang={lang}
        title="Bewerbung aka digitale Türpolitik"
        titleEn="Application aka Digital Door"
        progressValue={75}
        progressLabel={"3/4"}
      >
        {lang === "de" ? (
          <>
            <p>
              Jetzt geht es um deinen Wissensstand - dabei sei gesagt, dass auch
              wir nicht allwissend sind und dieser Test überhaupt keinen
              Anspruch hat perfekt und mit einem 1 Sternchen ausgefüllt zu
              werden. Wir wollen nur wissen, wie sehr ihr mit Themen bewandert
              seid, die zu einem wundervollen Miteinander beitragen.
            </p>
            <p>
              Sex positive Konzepte sind hochsensible Umgebungen in denen man
              nicht nur den Körper frei macht. Grenzüberschreitungen können hier
              schwere Wunden hinterlassen! Hat jemand den Eindruck, dass so ein
              Fehltritt absichtlich oder sogar böswillig passiert, sprechen wir
              von übergriffigem Verhalten, und da verstehen wir auch bei all dem
              Wiener Schmäh keinen Spaß.
            </p>
          </>
        ) : (
          // ENGLISH
          <>
            <p>
              Now it's about your level of knowledge - it should be said that we
              are not omniscient either and this questionnaire has no claim to
              be completed perfectly. We just want to know how familiar you are
              with topics that contribute to a wonderful sex positive Party.
            </p>
            <p>
              Sex positive concepts are highly sensitive environments - the
              guests don’t only free their body but also their inner selfs by
              playing with genders or working on their traumas. Crossing borders
              can cause serious wounds here! If someone has the impression that
              such a misstep happened intentionally or even maliciously, we
              speak of abusive behavior, and we don't take that as a joke, even
              with all the viennese "Schmäh".
            </p>
          </>
        )}
      </PageHead>
      <div className="categories">
        <Container>
          <Row id="questionnaire">
            <Col sm={12}>
              <h2 className="categories__header">
                {lang === "de" ? "Der Fragebogen" : "The Questionnaire"}
              </h2>
              <div id="accordion">
                {this.state.questionnaire.map((cat) => {
                  let nrOfQuestions = cat.questions.length;
                  let nrOfAnswered = cat.questions.filter((q) =>
                    q.answers.some((a) => a.selected)
                  ).length;

                  // Always render first question when collapsed
                  const setDefaultQuestion = (cat) => {
                    this.setState({
                      selectedQuestionIndex: 0,
                      selectedQuestionCategory: cat,
                    });
                  };
                  return (
                    <div
                      className={
                        "card category category--" +
                        cat.category +
                        (nrOfAnswered === nrOfQuestions
                          ? " category--completed"
                          : "")
                      }
                      key={"cat_" + cat.category}
                    >
                      <div
                        className="category__header"
                        data-toggle="collapse"
                        data-target={"#questions_" + cat.category}
                        onClick={() => setDefaultQuestion(cat)}
                        aria-expanded="false"
                        aria-controls={"questions_" + cat.category}
                        id={"cat_" + cat.category}
                      >
                        <h2
                          className="category__headline"
                          style={{ display: "flex" }}
                        >
                          <div className="category__button">
                            <div className="">
                              <span className="category__name">
                                {cat.category}
                              </span>
                              <Progress
                                className="category__answers"
                                value={nrOfAnswered}
                                barClassName="answers__bar"
                                max={nrOfQuestions}
                              >
                                {nrOfAnswered}/{nrOfQuestions}
                              </Progress>
                            </div>
                            <div className="category__status">
                              <div className="category__status-icon">
                                {nrOfAnswered === nrOfQuestions ? (
                                  <svg
                                    width="1em"
                                    height="1em"
                                    viewBox="0 0 16 16"
                                  >
                                    <path
                                      fill="yellow"
                                      d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zM7 6.5C7 7.328 6.552 8 6 8s-1-.672-1-1.5S5.448 5 6 5s1 .672 1 1.5zM4.285 9.567a.5.5 0 0 1 .683.183A3.498 3.498 0 0 0 8 11.5a3.498 3.498 0 0 0 3.032-1.75a.5.5 0 1 1 .866.5A4.498 4.498 0 0 1 8 12.5a4.498 4.498 0 0 1-3.898-2.25a.5.5 0 0 1 .183-.683zM10 8c-.552 0-1-.672-1-1.5S9.448 5 10 5s1 .672 1 1.5S10.552 8 10 8z"
                                    ></path>
                                  </svg>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </div>
                        </h2>
                      </div>
                      <div
                        id={"questions_" + cat.category}
                        className="collapse questions"
                        aria-labelledby={"cat_" + cat.category}
                        data-parent="#accordion"
                      >
                        <div className="question__container">
                          {cat == this.state.selectedQuestionCategory &&
                            this.renderQuestion(
                              lang,
                              cat,
                              this.state.selectedQuestionIndex
                            )}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <Container>
        <Row>
          <Col
            sm="12"
            md={{
              offset: 2,
              size: 8,
            }}
          >
            {lang === "de" ? (
              <>
                <h4>Auswertung</h4>
                <p>
                  Die Punkte werden automatisiert zusammengezählt. Danach werden
                  die besten 1200 Bewerbungen auf Heterogenität untersucht und
                  gegebenenfalls dahingehend adaptiert. Alle Angenommenen
                  bekommen einen Code zur Ticketgenerierung zugeschickt bzw. zur
                  Verfügung gestellt.
                </p>
              </>
            ) : (
              // ENGLISH
              <>
                <h4>Evaluation</h4>
                <p>
                  {" "}
                  The points are summed up automatically. Afterwards, the best
                  1200 Applications are checked for heterogeneity and if needed
                  adjusted in that direction. All accepted will get a code for
                  generating a ticket.
                </p>
              </>
            )}
          </Col>
        </Row>
      </Container>

      <PageFooter
        lang={lang}
        onClickNext={this.confirmAnswers}
        onClickNextCondition={!allAnswered}
        onClickPrev={(e) => {
          const gender = this.state.gender;
          this.confirmGender(e, gender, false);
        }}
      />
    </div>
  );

  renderEnterLetterOfMotivation = (lang) => {
    const textareaOnChange = (e) => {
      if (e.target.value == "admin-hack") {
        this.state.questionnaire.forEach((cat) =>
          cat.questions.forEach((q) => (q.answers[0].selected = true))
        );

        this.setState({
          statement_of_motivation:
            "Zwanzig Wörter zu schreiben ist der Admin sich zu fein. Ebenso hat er kein Bock drauf sich durch alle Fragen jedesmal wieder von neuem durchzuklicken. Ok?",
          isAdmin: true,
        });
      } else this.setState({ statement_of_motivation: e.target.value });

      /*e.target.parentNode.dataset.value = e.target.value;*/
      e.target.style.height = "auto";
      e.target.style.height = `${e.target.scrollHeight}px`;
    };
    let wordsCount = this.state.statement_of_motivation
      .split(" ")
      .filter(Boolean).length;

    return (
      <>
        <PageHead
          lang={lang}
          title={`Motivationsschreiben`}
          titleEn={"Letter of motivation"}
          progressValue={50}
          progressLabel={"2/4"}
        >
          {lang === "de" ? (
            <>
              <p>
                So ihr sexpositiven Elfis. Diesmal wird es gleich am Anfang
                persönlich! Schreibt uns, was euch zu uns geführt hat und was
                ihr euch von der Zeit mit uns erwartet. Durch die
                Event-Beschreibung & unseren Auftritt generell ahnt ihr ja schon
                zirka, welche Stimmung & Gäste wir auf der Party gerne hätten.
              </p>

              <p>Folgende Fragen zur Inspiration:</p>
              <ul>
                <li>Wer bist du? Wieso passt du zu dieser Party?</li>
                <li>Wie trägst du zum Safer Space von anderen bei?</li>
                <li>Wie trägst du zu einer sex positiven Stimmung bei?</li>
                <li>
                  Was tust du, um deine eigenen Grenzen und die der anderen zu
                  schützen?
                </li>
                <li>
                  Was sind für dich die Möglichkeiten und Grenzen dieser
                  Veranstaltung?
                </li>
                <li>Wieso solltest genau du dieses Mal dabei sein?</li>
              </ul>

              <p>
                Voller Freude deinen Text zu lesen, <br />
                Das Haus! 🏠
              </p>
            </>
          ) : (
            // ENGLISH
            <>
              <p>
                So you sex-positive Elf. This time it will be personal right at
                the beginning!
                <br />
                Write us what led you to us and what you expect from the time
                with us. Through the event description & our appearance in
                general you already have an idea of what kind of mood & guests
                we would like to have at the party.
              </p>

              <p>Following questions for inspiration:</p>

              <ul>
                <li>Who are you? Why do you fit to this party?</li>
                <li>How do you contribute to the safer space of others?</li>
                <li>How do you contribute to a sex positive vibe?</li>
                <li>
                  What do you do to protect your own boundaries and those of
                  others?
                </li>
                <li>
                  What are the possibilities and limitations of this event for
                  you?
                </li>
                <li>Why should you be there this time?</li>
              </ul>

              <p>
                Full of joy to read your text,
                <br />
                The house! 🏠
              </p>
            </>
          )}
        </PageHead>
        <Container className="motivation-letter" id="motivation">
          <Row>
            <Col
              sm="12"
              md={{
                offset: 2,
                size: 8,
              }}
            >
              <FormGroup>
                <Label for="statement-of-motivation">
                  {lang === "de"
                    ? "Dein Motivationsschreiben:"
                    : "Your letter of motivation:"}
                </Label>
                <FormText color="white" className="motivation-letter__count">
                  {wordsCount < 20 ? (
                    lang === "de" ? (
                      <>
                        Du hast erst <strong>{wordsCount}</strong> von
                        mindestens 20 Wörtern.
                      </>
                    ) : (
                      <>
                        You only got <strong>{wordsCount}</strong> of 20 words
                        minimum.
                      </>
                    )
                  ) : lang === "de" ? (
                    <>
                      <div className="motivation-letter__count-icon">
                        <svg width="1em" height="1em" viewBox="0 0 16 16">
                          <path
                            fill="yellow"
                            d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zM7 6.5C7 7.328 6.552 8 6 8s-1-.672-1-1.5S5.448 5 6 5s1 .672 1 1.5zM4.285 9.567a.5.5 0 0 1 .683.183A3.498 3.498 0 0 0 8 11.5a3.498 3.498 0 0 0 3.032-1.75a.5.5 0 1 1 .866.5A4.498 4.498 0 0 1 8 12.5a4.498 4.498 0 0 1-3.898-2.25a.5.5 0 0 1 .183-.683zM10 8c-.552 0-1-.672-1-1.5S9.448 5 10 5s1 .672 1 1.5S10.552 8 10 8z"
                          ></path>
                        </svg>
                      </div>
                      Nice! Du hast das Minimum von 20 Wörtern (ganze{" "}
                      {wordsCount}!) erreicht, aber schreibe gern mehr, um dich
                      hervorzuheben bei der Bewerbung. :)
                    </>
                  ) : (
                    <>
                      <div className="motivation-letter__count-icon">
                        <svg width="1em" height="1em" viewBox="0 0 16 16">
                          <path
                            fill="yellow"
                            d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zM7 6.5C7 7.328 6.552 8 6 8s-1-.672-1-1.5S5.448 5 6 5s1 .672 1 1.5zM4.285 9.567a.5.5 0 0 1 .683.183A3.498 3.498 0 0 0 8 11.5a3.498 3.498 0 0 0 3.032-1.75a.5.5 0 1 1 .866.5A4.498 4.498 0 0 1 8 12.5a4.498 4.498 0 0 1-3.898-2.25a.5.5 0 0 1 .183-.683zM10 8c-.552 0-1-.672-1-1.5S9.448 5 10 5s1 .672 1 1.5S10.552 8 10 8z"
                          ></path>
                        </svg>
                      </div>
                      Nice! You've reached the minimum of 20 words (whole{" "}
                      {wordsCount}!), but feel free to write more to make
                      yourself stand out in the application. :)
                    </>
                  )}
                </FormText>

                <div className="motivation-letter__input-wrapper">
                  <Input
                    type="textarea"
                    bsSize="lg"
                    rows={3}
                    name="text"
                    id="statement-of-motivation"
                    className="motivation-letter__input"
                    placeholder={
                      lang === "de"
                        ? "z.B. Ich bin perfekt geeignet, weil ich das beste Wesen auf der Welt bin..."
                        : "e.g. I am perfectly suited because I am the best being in the world..."
                    }
                    value={this.state.statement_of_motivation}
                    onChange={textareaOnChange}
                  />
                </div>
              </FormGroup>
              <FormGroup className="motivation-letter__checkbox" check>
                <Input
                  id={"consent_to_publish"}
                  name={"consent_to_publish"}
                  type="checkbox"
                  onChange={(e) =>
                    this.setState({
                      consent_to_publish: !this.state.consent_to_publish,
                    })
                  }
                />
                <Label check htmlFor={"consent_to_publish"}>
                  {lang === "de"
                    ? "Ja, ihr dürft meinen Text anonymisiert als Vorzeigetext verwenden (für Social Media etc.)"
                    : "Yes, you may use my text anonymously as a showcase text (for social media etc.)"}
                </Label>
              </FormGroup>
            </Col>
          </Row>
        </Container>
        <PageFooter
          lang={lang}
          onClickNext={this.confirmLetterOfMotivation}
          onClickNextCondition={wordsCount < 20}
        />
      </>
    );
  };

  renderEnterName = (lang) => (
    <>
      <PageHead
        lang={lang}
        title={"Personalien"}
        titleEn={"Personal Data"}
        progressValue={98}
        progressLabel={"4/4"}
      >
        {lang === "de" ? (
          <>
            <h2>Gleich hast du es geschafft 🤩</h2>
            <p>
              Nun müssen wir nur noch deine Bewerbung mit deinem Namen
              verknüpfen. Nochmal zum Verständnis: dein Klarname wird nicht
              gespeichert. Wir arbeiten mit{" "}
              <a
                href="https://praxistipps.chip.de/hashwert-was-ist-das-einfach-und-verstaendlich-erklaert_44082"
                target="_blank"
                rel="noopener noreferrer"
              >
                Hashes
              </a>{" "}
              bzw. starker Verschlüsselung, um deine Daten (Name, E-Mail) sogar
              vor uns selber zu verstecken. Selbst deine e-Mail wird
              kryptographisch gespeichert (z.B.:
              fsYq4qL8Q-tFzVq39EXhbjiBXI5k73ll4uSxu4YXFQc) und kann nur mit
              einem Crypto-Schlüssel ausgelesen werden.
            </p>
          </>
        ) : (
          <>
            <h2>You almost made it 🤩</h2>
            <p>
              We now have to link your Name to your application. Once again for
              your information: your name is not being saved. We use a{" "}
              <a
                href="https://computersciencewiki.org/index.php/Hashing"
                target="_blank"
                rel="noopener noreferrer"
              >
                Hash-Code
              </a>{" "}
              generator and advanced cryptography in the background to hide your
              personal data (name, e-mail) even from ourselves. We even encrypt
              strongly your e-mail (e.g.:
              fsYq4qL8Q-tFzVq39EXhbjiBXI5k73ll4uSxu4YXFQc), which will be
              decrypted on-the-fly to send you updates on the process.
            </p>
          </>
        )}
      </PageHead>
      <Container className="personal-data" id="personalia">
        <Row>
          <Col
            sm="12"
            md={{
              offset: 2,
              size: 8,
            }}
          >
            <Form>
              <Row form>
                <Col>
                  <Label for="iptBiometricId">
                    {lang === "de"
                      ? "Biometrische Ausweisnummer"
                      : "Biometirc ID"}
                  </Label>
                  <Input
                    required
                    bsSize="lg"
                    type="text"
                    id="iptBiometricId"
                    placeholder={
                      lang === "de" ? "z.B. U0427760" : "e.g. U0427760"
                    }
                    disabled={
                      this.state.given_name !== "" &&
                      this.state.family_name !== ""
                    }
                    value={this.state.biometric_id}
                    autoComplete="BIOMETRIC_ID"
                    name="BIOMETRIC_ID"
                    id="BIOMETRIC_ID"
                    onChange={(e) =>
                      this.setState({
                        biometric_id: e.target.value,
                        given_name: "",
                        family_name: "",
                      })
                    }
                  ></Input>
                  <FormText>
                    {lang === "de"
                      ? "Gib die Nummer deines biometrischen Ausweises ein. Bitte überprüfe es doppelt da wir nur biometrische Ausweise beim Einlass akzeptieren können!"
                      : "Enter the number of your biometric ID. Please double-check as we can only accept biometric IDs at the event!"}
                    <br />

                    <details
                      style={{
                        marginTop: "1rem",
                      }}
                    >
                      <summary
                        style={{
                          cursor: "pointer",
                          fontSize: "1.2rem",
                          fontWeight: "bold",
                          padding: "0.5rem 0",
                          color: "#fff",
                        }}
                      >
                        {lang === "de"
                          ? "Info zur biometrischen Ausweisnummer"
                          : "Info on the biometric ID"}
                      </summary>

                      <BiometricIdInfo lang={lang} />
                    </details>
                  </FormText>
                </Col>
              </Row>
              <Row
                style={{
                  justifyContent: "center",
                  padding: "1.5rem 0",
                  fontSize: "1.25rem",
                }}
              >
                {lang === "de" ? "ODER" : "OR"}
              </Row>

              <Row form>
                <Col md={6}>
                  <FormGroup>
                    <Label for="iptGivenname">
                      {lang === "de" ? "Vorname" : "First Name"}
                    </Label>
                    <Input
                      required
                      bsSize="lg"
                      type="text"
                      id="iptGivenname"
                      placeholder={lang === "de" ? "z.B. Maxi" : "e.g. Maxi"}
                      value={this.state.given_name}
                      autoComplete="VORNAME"
                      disabled={this.state.biometric_id !== ""}
                      name="VORNAME"
                      onChange={(e) =>
                        this.setState({
                          given_name: e.target.value,
                          biometric_id: "",
                        })
                      }
                    ></Input>
                    <FormText>
                      {lang === "de"
                        ? "Exakt wie auf dem Ausweisdokument."
                        : "Exactly as on the identification document."}
                    </FormText>
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label for="iptFamilyname">
                      {lang === "de" ? "Nachname" : "Last Name"}
                    </Label>
                    <Input
                      required
                      bsSize="lg"
                      type="text"
                      id="iptFamilyname"
                      placeholder={lang === "de" ? "z.B. Herz" : "e.g. Weasly"}
                      value={this.state.family_name}
                      autoComplete="NACHNAME"
                      disabled={this.state.biometric_id !== ""}
                      name="NACHNAME"
                      id="NACHNAME"
                      onChange={(e) =>
                        this.setState({
                          family_name: e.target.value,
                          biometric_id: "",
                        })
                      }
                    ></Input>
                    <FormText>
                      {lang === "de"
                        ? "Auch dieser muss exakt wie auf dem Ausweisdokument sein."
                        : "This must also be exactly as on the identification document."}
                    </FormText>
                  </FormGroup>
                </Col>
              </Row>

              <hr
                style={{
                  padding: "1rem 0",
                  border: "none",
                  borderTop: "1px solid #fff",
                }}
              />

              <FormGroup>
                <Label for="iptMail">E-Mail*</Label>
                <Input
                  bsSize="lg"
                  required
                  type="email"
                  id="iptMail"
                  placeholder={
                    lang === "de"
                      ? "z.B. maxi.herz@gmail.com"
                      : "e.g. maxi.herz@gmail.com"
                  }
                  value={this.state.email}
                  autoComplete="EMAIL"
                  id="EMAIL"
                  name="EMAIL"
                  onChange={(e) => this.setState({ email: e.target.value })}
                ></Input>

                <FormText>
                  {lang === "de" ? (
                    <>
                      Gib deine e-Mail Adresse an, dann kriegst du von uns eine
                      E-Mail ob du auf der <strong>Gästeliste</strong> bist.
                    </>
                  ) : (
                    // ENGLISH
                    <>
                      Enter your e-mail address and we will send you an e-mail
                      if you are on the <strong>guest list</strong>.
                    </>
                  )}
                </FormText>
              </FormGroup>

              <FormGroup className="personal-data__checkbox" check>
                <Input
                  id={"newsletter_optin"}
                  checked={this.state.newsletter_optin}
                  name={"newsletter_optin"}
                  type="checkbox"
                  onChange={(e) =>
                    this.setState({
                      newsletter_optin: !this.state.newsletter_optin,
                    })
                  }
                />
                <Label check htmlFor={"newsletter_optin"}>
                  Ja, ich will auch euren Newsletter in Zukunft erhalten. (Wir
                  versenden nur Wunderbares, versprochen.)
                </Label>
              </FormGroup>
              <hr style={{ borderColor: "#4e746e" }} />
              <div>
                <h4>
                  {lang === "de"
                    ? "Als Gruppe / Paar anmelden"
                    : "Register as a group / couple"}
                </h4>
                {lang === "de" ? (
                  <p>
                    Ihr habt die Möglichkeit euch als Gruppe oder Paar
                    anzumelden. Dazu müssen alle den gleichen Gruppennamen bei
                    der Anmeldung angeben. Es wird zur Auswertung dann der
                    Durchschnitt aller genommen. Die Mitglieder pro Gruppe sind
                    auf 4 begrenzt. Wird keine Gruppe angegeben, dann erstellen
                    wir random eine, damit ihr nachträglich eure Freunde euch
                    joinen lassen könnt.
                  </p>
                ) : (
                  // ENGLISH

                  <p>
                    You have the possibility to register as a group or couple.
                    To do this, all must specify the same group name when
                    registering. The average of all will be taken for the
                    evaluation. The members per group are limited to 4. If no
                    group is specified, we will randomly create one so that you
                    can let your friends join you afterwards.
                  </p>
                )}
              </div>
              <FormGroup>
                <Label for="iptGroup">
                  {lang === "de" ? "Euer Gruppenname" : "Your group name"}
                </Label>
                <Input
                  bsSize="md"
                  type="text"
                  id="iptGroup"
                  placeholder={
                    lang === "de"
                      ? "z.B. HottestCouple69"
                      : "e.g. HottestCouple69"
                  }
                  value={this.state.group_name}
                  autoComplete="off"
                  name="group_name"
                  onChange={(e) => this.checkGroupName(e.target.value)}
                ></Input>
                <FormText>
                  {lang === "de" ? (
                    <>
                      Alle Mitglieder:innen müssen den selben Gruppennamen bei
                      der Anmeldung angeben. Gruppe sind auf{" "}
                      <strong>4 Mitglieder*innen</strong> begrenzt. Wenn keine
                      Gruppe angegeben wird, wird eine random erstellt.
                    </>
                  ) : (
                    // ENGLISH
                    <>
                      All members must use the same group name when
                      registering.Groups are limit to <strong>4 members</strong>
                      . If you leave this field blank we will create a random
                      group for you.
                    </>
                  )}
                </FormText>
                <FormText color="white">
                  <span
                    dangerouslySetInnerHTML={{
                      __html: this.renderGroupStatus(this.state.group_status),
                    }}
                  ></span>
                </FormText>
              </FormGroup>

              <Button
                color="primary"
                size="lg"
                disabled={
                  (!this.state.biometric_id &&
                    !(this.state.given_name && this.state.family_name)) ||
                  !this.state.email ||
                  this.state.group_status === "MAXMEMBERS"
                }
                onClick={this.postResponsesToServer}
              >
                {lang === "de" ? "Registrieren" : "Sign up"}
              </Button>

              {this.state.biometric_id ? (
                <FormText color="white">
                  <p
                    style={{
                      fontSize: "1.2rem",
                      fontWeight: "bold",
                      padding: "1rem 0",
                    }}
                  >
                    {lang === "de"
                      ? "Du nutzt eine Ausweisnummer für die Identifikation. Bitte prüfe nochmal ob du sicher einen biometrischen Ausweis hast!"
                      : "You are using an ID number for identification. Please double check if you have a biometric ID!"}
                  </p>

                  <BiometricIdInfo lang={lang} />
                </FormText>
              ) : null}
            </Form>
          </Col>
        </Row>
      </Container>
    </>
  );

  getBrowserName = () => {
    let sBrowser,
      sUsrAg = navigator.userAgent;

    // The order matters here, and this may report false positives for unlisted browsers.
    if (sUsrAg.indexOf("Firefox") > -1) {
      sBrowser = "Mozilla Firefox";
      // "Mozilla/5.0 (X11; Ubuntu; Linux x86_64; rv:61.0) Gecko/20100101 Firefox/61.0"
    } else if (sUsrAg.indexOf("SamsungBrowser") > -1) {
      sBrowser = "Samsung Internet";
      // "Mozilla/5.0 (Linux; Android 9; SAMSUNG SM-G955F Build/PPR1.180610.011) AppleWebKit/537.36 (KHTML, like Gecko) SamsungBrowser/9.4 Chrome/67.0.3396.87 Mobile Safari/537.36
    } else if (sUsrAg.indexOf("Opera") > -1 || sUsrAg.indexOf("OPR") > -1) {
      sBrowser = "Opera";
      // "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_14_0) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/70.0.3538.102 Safari/537.36 OPR/57.0.3098.106"
    } else if (sUsrAg.indexOf("Trident") > -1) {
      sBrowser = "Microsoft Internet Explorer";
      // "Mozilla/5.0 (Windows NT 10.0; WOW64; Trident/7.0; .NET4.0C; .NET4.0E; Zoom 3.6.0; wbx 1.0.0; rv:11.0) like Gecko"
    } else if (sUsrAg.indexOf("Edge") > -1) {
      sBrowser = "Microsoft Edge (Legacy)";
      // "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/58.0.3029.110 Safari/537.36 Edge/16.16299"
    } else if (sUsrAg.indexOf("Edg") > -1) {
      sBrowser = "Microsoft Edge (Chromium)";
      // Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/91.0.4472.124 Safari/537.36 Edg/91.0.864.64
    } else if (sUsrAg.indexOf("Chrome") > -1) {
      sBrowser = "Google Chrome or Chromium";
      // "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Ubuntu Chromium/66.0.3359.181 Chrome/66.0.3359.181 Safari/537.36"
    } else if (sUsrAg.indexOf("Safari") > -1) {
      sBrowser = "Apple Safari";
      // "Mozilla/5.0 (iPhone; CPU iPhone OS 11_4 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/11.0 Mobile/15E148 Safari/604.1 980x1306"
    } else {
      sBrowser = "unknown";
    }
    return sBrowser;
  };

  getOperatingSystem = () => {
    let os = "unknown";

    if (navigator.appVersion.indexOf("Win") !== -1) os = "Windows";
    if (navigator.appVersion.indexOf("Mac") !== -1) os = "MacOS";
    if (navigator.appVersion.indexOf("X11") !== -1) os = "UNIX";
    if (navigator.appVersion.indexOf("Linux") !== -1) os = "Linux";

    return os;
  };

  getViewportSize = () => {
    const vw = Math.max(
      document.documentElement.clientWidth || 0,
      window.innerWidth || 0
    );
    const vh = Math.max(
      document.documentElement.clientHeight || 0,
      window.innerHeight || 0
    );

    return vw + "x" + vh;
  };

  buildHelpMailto = (subj, body) => {
    function utf8_to_b64(str) {
      return window.btoa(unescape(encodeURIComponent(str)));
    }

    let responseAsJson = JSON.stringify(this.state.response);
    let responseAsB64 = utf8_to_b64(responseAsJson);

    console.log("buildHelpMailto: responseAsB64", responseAsB64);

    let mailBody =
      body +
      "\n\n------------- MY APPLICATION: ----------------\n\n\n" +
      responseAsB64 +
      "\n\n\n--------------------------------------------";

    let mailSubject = encodeURIComponent(subj);
    mailBody = encodeURIComponent(mailBody);

    let mailUri = `mailto:it@hausgemacht.org?subject=${mailSubject}&body=${mailBody}`;
    return mailUri;
  };

  renderAnswersBeingSubmitted = (lang) => {
    let helpBody =
      "EINFACH ABESENDEN, DANKE :)\n\n\n" +
      "Browser:\n" +
      this.getBrowserName() +
      "\n\n" +
      "OS:\n" +
      this.getOperatingSystem() +
      "\n\n" +
      "Viewport:\n" +
      this.getViewportSize() +
      "\n\n";

    let mailUri = this.buildHelpMailto("[ZK System] Error", helpBody);

    return (
      <PageHead lang={lang} title={"Hold the line! ⏳"}>
        <Spinner color="light" />
        {lang === "de" ? (
          <p>… deine Angaben werden gerade vom Server bearbeitet.</p>
        ) : (
          <p>… your input is being processed by our servers.</p>
        )}
        <p>
          If this takes longer than 20 seconds, please{" "}
          <a href={mailUri} target="_blank">
            contact us
          </a>
          .
        </p>
      </PageHead>
    );
  };

  renderShowQRCode = (lang) => {
    let fullName = `${
      this.state.given_name
    } ${this.state.family_name.toUpperCase()}`;

    let helpMailBody =
      "EINFACH ABSENDEN, DANKE :) \n\n" +
      "Fehler:\n" +
      "Ich habe leider keine Bestätigungs E-Mail erhalten. Ich sende von der E-Mail, mit der ich mich eingetragen habe.\n\n" +
      "Danke!" +
      "----------\n\n" +
      "Browser:\n" +
      this.getBrowserName() +
      "\n\n" +
      "OS:\n" +
      this.getOperatingSystem() +
      "\n\n" +
      "Viewport:\n" +
      this.getViewportSize() +
      "\n\n";

    let mailUri = this.buildHelpMailto(
      "[ZK System] Missing Mail",
      helpMailBody
    );

    let qrcode = JSON.stringify({
      n: fullName,
      h: App.buildMiniHash(fullName),
      e: this.state.eventId,
      g: this.state.guid_response,
    });
    const textareaFeedbackOnChange = (e) => {
      this.setState({ feedbackTxt: e.target.value });

      e.target.style.height = "inherit";
      e.target.style.height = `${e.target.scrollHeight}px`;
    };

    const sendFeedback = () => {
      const message = this.state.feedbackTxt;
      const sendFeedback = fetch(
        process.env.REACT_APP_API_BASE_URL +
          "//api/feedback?event_id=" +
          this.state.eventId +
          "&msg=" +
          message
      )
        .then((res) => res.text())
        .then((root) => {
          alert("Danke für dein Feedback. <3 ");
        });
    };
    return (
      <Container className="successful">
        <Row id="qrcode">
          <Col
            sm="12"
            md={{
              offset: 2,
              size: 8,
            }}
          >
            {lang === "de" ? (
              <>
                <h2>
                  Bewerbung abgeschickt{" "}
                  <span role="img" aria-label="Bunniez">
                    👯😘
                  </span>
                </h2>
                <p>
                  Du Zauberwesen! Danke! Wir wissen es zu schätzen, dass du dir
                  eben so viel Zeit für die Party, andere Gäste und uns genommen
                  hast. Jetzt ist es an uns, den richtigen Mix von Gästen
                  einzuladen, um allen eine spannende Nacht zu garantieren.{" "}
                </p>
                <p>
                  Wir bearbeiten jetzt alle Bewerbungen und melden uns bei einer
                  Zusage (nach Ablauf der Bewerbungsfrist) mit allen weiteren
                  Schritten.
                </p>
                <p>
                  Bitte nimm es nicht persönlich, wenn es keine Zusage gibt -
                  das hat mehr mit unserer Vorstellung von einem durchgemischten
                  Publikum als mit dir zu tun.
                </p>
                <p>
                  Ein Reminder: Wir halten deine E-Mail Adresse stark
                  verschlüsselt gespeichert, auf deine persönlichen Daten (Name,
                  E-Mail) haben selbst wir keinen Zugriff! Solltest du also
                  Fragen zu deiner Bewerbung haben, so gib bitte immer den
                  Status-Link an, damit wir dir weiterhelfen können.
                </p>

                <p>
                  Unter diesem Link kannst du den Status deiner Bewerbung
                  abfragen:
                </p>

                <div className="hash-link">
                  <Button
                    color="link"
                    onClick={() =>
                      this.props.history.push(
                        `/events/${this.state.eventId}/guestlist#${this.state.guid_response}`
                      )
                    }
                  >
                    {`${this.state.urlRoot}/events/${this.state.eventId}/guestlist#${this.state.guid_response}`}
                  </Button>
                </div>
                <div className="group-name">
                  <span>Deine Gruppe:</span>
                  <h4>{this.state.group_name}</h4>
                </div>
                <p>
                  <strong>
                    Bitte unbedingt deinen SPAM-Ordner überprüfen.
                  </strong>
                  <br />
                  Falls du keine E-Mail von uns erhalten hast, dann sende uns
                  eine{" "}
                  <a target="_blank" href={mailUri} title="E-Mail senden">
                    E-Mail
                  </a>
                  .
                </p>

                <p>
                  Liebvolle Küsse,
                  <br />
                  Das Haus 🏠
                </p>

                <p>
                  PS: Hier findest du die ...{" "}
                  <a href="http://www.hausgemacht.org/faq">FAQs!</a>
                </p>

                <h4>Dein Feedback an uns</h4>
                <p>
                  Hast du Anregungen oder Ideen wie wir den Prozess besser
                  machen können?
                </p>
                <textarea
                  className="feedback-input"
                  value={this.state.feedbackTxt}
                  onChange={textareaFeedbackOnChange}
                  name="feedback"
                  id="feedback"
                  cols="20"
                  rows="4"
                  placeholder="z.B. Man könnte Frage XYZ ein wenig umformulieren; Folgende Fragen wären wichtig, damit ich mich sicherer fühle; etc."
                ></textarea>
                <Button onClick={sendFeedback}>Feedback anonym absenden</Button>
              </>
            ) : (
              // ENGLISH
              <>
                <h2>
                  Application submitted{" "}
                  <span role="img" aria-label="Bunniez">
                    👯😘
                  </span>
                </h2>
                <p>
                  You magic creature! Thanks! We appreciate that you just took
                  so much time for the party, other guests and us. Now it's up
                  to us to invite the right mix of guests to guarantee an
                  exciting night for everyone.
                </p>
                <p>
                  We are now processing all applications and will contact you if
                  you have been accepted (after the application deadline) with
                  all further steps.
                </p>
                <p>
                  Please don't take it personally if you don't get accepted -
                  this has more to do with our idea of ​​a mixed crowd than with
                  you.
                </p>
                <p>
                  A reminder: We store your e-mail address in highly encrypted
                  form, and we do not have access to your personal data (name,
                  e-mail)! If you have any questions about your application,
                  please always provide the status link so that we can help you.
                </p>

                <p>
                  The status of the application can be queried under this link:
                </p>
                <div className="hash-link">
                  <Button
                    color="link"
                    onClick={() =>
                      this.props.history.push(
                        `/events/${this.state.eventId}/guestlist#${this.state.guid_response}`
                      )
                    }
                  >
                    {`${this.state.urlRoot}/events/${this.state.eventId}/guestlist#${this.state.guid_response}`}
                  </Button>
                </div>
                <p>
                  <strong>Please make sure to check your SPAM folder.</strong>
                  <br />
                  If you have not received an email from us, please send us an{" "}
                  <a target="_blank" href={mailUri} title="E-Mail senden">
                    E-Mail
                  </a>
                  .
                </p>
                <p>
                  Otherwise: thank you for your effort, we do really appreciate
                  it 🙏
                </p>

                <p>
                  Loving kisses,
                  <br />
                  The house 🏠
                </p>

                <p>
                  PS: Here you can find the ...{" "}
                  <a href="http://www.hausgemacht.org/faq">FAQs!</a>
                </p>

                <h4>Your Feedback is needed</h4>
                <p>
                  Do you have any suggestions or ideas on how we can make the
                  process better?
                </p>

                <textarea
                  value={this.state.feedbackTxt}
                  className="feedback-input"
                  onChange={textareaFeedbackOnChange}
                  name="feedback"
                  id="feedback"
                  cols="20"
                  rows="4"
                  placeholder="e.g. You could rephrase question XYZ a little; The following questions would be important to make me feel more safe; etc."
                ></textarea>
                <Button onClick={sendFeedback}>
                  Send feedback anonymously
                </Button>
              </>
            )}
          </Col>
        </Row>
      </Container>
    );
  };

  renderQuestion = (lang, category, i) => {
    let q = category.questions[i];
    let questionId = q.id;

    return (
      <div className="question">
        <h3 className="question__category">
          {i + 1}. {lang === "de" ? "Frage" : "Question"}
        </h3>
        <div className="question-container">
          <div className="question__content">
            <h3 className="question__name">{q.question}</h3>
            {questionId === "Q1" && (
              <p className="">
                <a
                  className="question__link"
                  target="_blank"
                  href="https://www.hrc.org/resources/marriage-equality-around-the-world"
                >
                  Marriage Equality around the World
                </a>
                <br />
                {lang === "de" && (
                  <a
                    className="question__link"
                    target="_blank"
                    href="https://fra.europa.eu/sites/default/files/fra_uploads/lgbti_survey_-_general_qa_de.pdf"
                  >
                    LGBTI-Erhebung der FRA
                  </a>
                )}
                {lang === "en" && (
                  <a
                    className="question__link"
                    target="_blank"
                    href="https://fra.europa.eu/sites/default/files/fra_uploads/lgbti_survey_-_general_qa.pdf"
                  >
                    FRA's LGBTI survey
                  </a>
                )}
                <br />
                <a
                  className="question__link"
                  target="_blank"
                  href="https://www.humandignitytrust.org/lgbt-the-law/map-of-criminalisation/"
                >
                  Map of Countries that Criminalise LGBT People
                </a>
              </p>
            )}
            <div className="question__choices">
              {q.answers.map((a) => {
                let answerId = questionId + "." + a.id;
                return (
                  <label key={answerId} className="choicecontainer">
                    <input
                      disabled={this.state.hasConfirmedAnswers}
                      type={q.answer_type}
                      name={questionId}
                      checked={a.selected}
                      id={answerId}
                      onChange={(e) => {
                        if (q.answer_type === "checkbox")
                          a.selected = e.target.checked;
                        else if (q.answer_type === "radio" && e.target.checked)
                          q.answers.forEach(
                            (answ) => (answ.selected = answ.id === a.id)
                          );
                        else
                          throw Error(
                            `unsupported answer indicator: ${q.answer_type}, ${e.target.checked}`
                          );

                        this.forceUpdate();
                      }}
                    />
                    <span
                      className={"checkmark checkmark_" + q.answer_type}
                    ></span>
                    <span className="choice">{a.answer}</span>
                  </label>
                );
              })}
            </div>
          </div>
        </div>
        <div className="question__navigation">
          <div className="question__navigation-prev">
            {i > 0 ? (
              <Button
                onClick={(e) => {
                  this.setState({ selectedQuestionIndex: i - 1 });
                }}
              >
                <span className="d-inline d-lg-none">
                  <svg width="1em" height="1em" viewBox="0 0 16 16">
                    <path
                      fill="currentColor"
                      fillRule="evenodd"
                      d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
                    ></path>
                  </svg>
                </span>
                <span className="d-none d-lg-inline">
                  {lang === "de" ? "Vorherige Frage" : "Previous"}
                </span>
              </Button>
            ) : (
              ""
            )}
          </div>
          <div className="question__navigation-pagination">
            {category.questions.map((question, j) => (
              <Button
                className={
                  "pagination-item" +
                  (question.answers.filter((a) => a.selected).length > 0
                    ? " answered"
                    : "")
                }
                color={i === j ? "primary" : "secondary"}
                disabled={i === j}
                key={"q" + j}
                onClick={(e) => {
                  this.setState({ selectedQuestionIndex: j });
                }}
              >
                <span>{j + 1}</span>
                {question.answers.filter((a) => a.selected).length > 0 ? (
                  <span className="pagination-item__icon">
                    <svg width="1em" height="1em" viewBox="0 0 16 16">
                      <path
                        fill="currentColor"
                        d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093l3.473-4.425a.267.267 0 0 1 .02-.022z"
                      ></path>
                    </svg>
                  </span>
                ) : (
                  ""
                )}
              </Button>
            ))}
          </div>

          <div className="question__navigation-next">
            {i + 1 < category.questions.length ? (
              <Button
                color="primary"
                onClick={(e) => {
                  this.setState({ selectedQuestionIndex: i + 1 });
                }}
              >
                <span className="d-none d-lg-inline">
                  {lang === "de" ? "Nächste Frage" : "Next question"}
                </span>
                <span>
                  <svg width="1em" height="1em" viewBox="0 0 16 16">
                    <path
                      fill="currentColor"
                      fillRule="evenodd"
                      d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                    ></path>
                  </svg>
                </span>
              </Button>
            ) : category.questions.length ===
              category.questions.filter((q) =>
                q.answers.some((a) => a.selected)
              ).length ? (
              lang === "de" ? (
                "Fertig, ab zur nächsten Kategorie. :)"
              ) : (
                "Ready, off to the next category. :)"
              )
            ) : lang === "de" ? (
              "Bitte alle Fragen beantworten"
            ) : (
              "Please answer all questions"
            )}
          </div>
        </div>
      </div>
    );
  };
}

export default withRouter(Questionnaire);
