import React, { Component } from 'react'
import { Route, Switch } from 'react-router'
import { Col, Container, Row } from 'reactstrap';
import { Layout } from './components/Layout'
import Questionnaire, {} from './components/Questionnaire'
import './custom.css'
import './css/app.scss'
import sjcl from 'sjcl'
import { Guestlist } from './components/Guestlist'
import { ApplicationInfo } from './components/ApplicationInfo'
import { Register } from './components/Register'
import { Home } from './components/Home'
import Login, {} from './components/Login/Login'
import UserProfile, {} from './components/UserProfile'
import Gatekeeper from './components/Gatekeeper'
import Evaluate from './components/Evaluate'
import TestFeatures from './components/TestFeatures'
import BoxInfo from './components/BoxInfo';

export default class App extends Component {

    state = {
        session: null,
        language: "de"
    }

    setSession = (session) => {
        this.setState({ session });
    }

    setLanguage = (language) => {
        this.setState({ language }, () => console.log("App.js -> state: language set to: " + this.state.language));
    }

    static makeSHA256Hash = (text) =>
        sjcl.codec.base64url.fromBits(sjcl.hash.sha256.hash(text));

    static buildMiniHash = (text) => {

        function getRndInteger(min, max) {
            return Math.floor(Math.random() * (max - min + 1)) + min;
        }

        let hash = this.makeSHA256Hash(text);
        let miniHashIndexes = [];
        let miniHashChars = [];

        for (let i = 0; i < 5; i++) {
            let idx = getRndInteger(0, hash.length - 1);
            miniHashIndexes.push(idx);
            miniHashChars.push(hash[idx]);
        }

        console.log("buildMiniHash:", hash, miniHashIndexes, miniHashChars);

        return `${miniHashIndexes.join('.')} ${miniHashChars.join('')}`;
    }

    static isValidMiniHash = (text, miniHash) => {

        let hash = this.makeSHA256Hash(text);
        let miniHashParts = miniHash.split(' ');
        let miniHashIndexes = miniHashParts[0].split('.');

        console.log("validateMiniHash:", hash, miniHash, miniHashIndexes);

        let anyWrongIndexes = miniHashIndexes.some((idx, i) => miniHashParts[1][i] !== hash[idx]);
        return !anyWrongIndexes;
    }

    render() {

        return (
            <Switch>
                <Route exact path='/gatekeeper' component={Gatekeeper} />
                <Route exact path='/boxinfo' component={BoxInfo} />
                <Route exact path='/testfeatures' component={TestFeatures} />
                <Route path='/api'>
                    <h1>Hm ...</h1>
                    <p>... wenn du das siehst, dann stimmt was mit deinem Browser nicht.<br />Bitte kontaktiere
                        die IT!</p>
                </Route>
                <Route path='/'>
                    <Layout setLanguage={this.setLanguage} logOut={() => this.setState({ session: null })}
                        state={this.state}>
                        <Switch>
                            <Route exact path='/' component={Home} />
                            <Route exact path='/events' component={Home} />
                            <Route exact path='/events/:eventId/questionnaire' render={(props) =>
                                <Questionnaire state={this.state} {...props} setLanguage={this.setLanguage} />
                            } />
                            <Route exact path='/events/:eventId/guestlist' render={(props) =>
                                <Guestlist state={this.state} {...props} />
                            } />
                            <Route exact path='/events/:eventId/applications/:applicationId' render={(props) =>
                                <ApplicationInfo setLanguage={this.setLanguage} state={this.state} {...props} />
                            } />
                            <Route exact path='/events/:eventId/evaluate' component={Evaluate} />
                            <Route exact path='/regulars/register' component={Register} />
                            <Route exact path='/regulars/login' render={(props) =>
                                <Login state={this.state} setSession={this.setSession} {...props} />
                            } />
                            <Route exact path='/regulars/profile' render={(props) =>
                                <UserProfile state={this.state} {...props} />
                            } />
                            <Route>
                                <Container>
                                    <Row>
                                        <Col
                                            sm="12"
                                            md={{
                                                offset: 2,
                                                size: 8
                                            }}><h1>404 ... das gibt es doch nicht!</h1>
                                        </Col>
                                    </Row>
                                </Container>

                            </Route>
                        </Switch>
                    </Layout>
                </Route>
            </Switch>
        );
    }
}