import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Container, Row } from 'reactstrap';

const QGender = (props) => {
  return (
    <>
      <Container>
        <Row>
          <Col
            sm="12"
            md={{
              offset: 2,
              size: 8
            }}>

            <Row>
              <Col xs={6} lg={3}>
                <div className="genderOption genderOption--female"
                     onClick={(e) => props.handleClick("female")}
                     data-selected={props.gender === "female"}>
                  <div className="genderIcon"><span role="img" aria-label="Female"><svg width="1em"
                                                                                        height="1em"
                                                                                        viewBox="0 0 256 256"><path
                    fill="currentColor"
                    d="M208 96a80 80 0 1 0-88 79.6V200H88a8 8 0 0 0 0 16h32v24a8 8 0 0 0 16 0v-24h32a8 8 0 0 0 0-16h-32v-24.4A80.1 80.1 0 0 0 208 96ZM64 96a64 64 0 1 1 64 64a64.1 64.1 0 0 1-64-64Z"></path></svg></span>
                  </div>
                  <span className="genderLabel">{props.lang === "de" ? "Weiblich" : "Female"}</span>
                </div>
              </Col>
              <Col xs={6} lg={3}>
                <div className="genderOption genderOption--male"
                     onClick={(e) => props.handleClick("male")}
                     data-selected={props.gender === "male"}>
                  <div className="genderIcon"><span role="img" aria-label="Mann"><svg width="1em"
                                                                                      height="1em"
                                                                                      viewBox="0 0 256 256"><path
                    fill="currentColor"
                    d="M216 32h-48a8 8 0 0 0 0 16h28.7l-42.1 42.1a80 80 0 1 0 11.3 11.3L208 59.3V88a8 8 0 0 0 16 0V40a8 8 0 0 0-8-8Zm-66.7 165.3a64 64 0 1 1 0-90.6a64.3 64.3 0 0 1 0 90.6Z"></path></svg></span>
                  </div>
                  <span className="genderLabel">{props.lang === "de" ? "Männlich" : "Male"}</span>
                </div>
              </Col>
              <Col xs={6} lg={3}>
                <div className="genderOption genderOption--non_binary"
                     onClick={(e) => props.handleClick("non_binary")}
                     data-selected={props.gender === "non_binary"}>
                  <div className="genderIcon"><span role="img" aria-label="Non Binär"><svg width="1em"
                                                                                           height="1em"
                                                                                           viewBox="0 0 256 256"><path
                    fill="currentColor"
                    d="M136 88.4V59l23.3 15.6a7.2 7.2 0 0 0 4.4 1.4a8 8 0 0 0 4.5-14.6L142.3 44l25.9-17.4a8 8 0 1 0-8.9-13.2l-31.3 21l-31.3-21a8 8 0 1 0-8.9 13.2L113.7 44L87.8 61.4A8 8 0 0 0 92.3 76a7.2 7.2 0 0 0 4.4-1.4L120 59v29.4a76 76 0 1 0 16 0ZM128 224a60 60 0 1 1 60-60a60 60 0 0 1-60 60Z"></path></svg></span>
                  </div>
                  <span className="genderLabel">{props.lang === "de" ? "Non Binär" : "Non Binary"}</span>
                </div>
              </Col>
              <Col xs={6} lg={3}>
                <div className="genderOption genderOption--neutral"
                     onClick={(e) => props.handleClick("neutral")}
                     data-selected={props.gender === "neutral"}>
                  <div className="genderIcon"><span role="img" aria-label="neutral"><svg width="1em"
                                                                                         height="1em"
                                                                                         viewBox="0 0 256 256"><path
                    fill="currentColor"
                    d="M208 104a80 80 0 1 0-88 79.6V232a8 8 0 0 0 16 0v-48.4a80.1 80.1 0 0 0 72-79.6Zm-80 64a64 64 0 1 1 64-64a64.1 64.1 0 0 1-64 64Z"></path></svg></span>
                  </div>
                  <span className="genderLabel">{props.lang === "de" ? "Keine Angabe" : "Not specified"}</span>
                </div>
              </Col>
            </Row>
          </Col>

        </Row>
      </Container>

    </>
  );
};

QGender.propTypes = {
  gender: PropTypes.string,
  handleClick: PropTypes.func,
  lang: PropTypes.string
};

export default QGender;
